/**
 * @class AnalyticsHelper
 * @classdesc
 * Helper to manage state updates for tracking components.
 * This class encapsulates the attribute change logic in order to have one place of managing it.
 */
export default class AnalyticsHelper {

    static trackingStateAttr = 'data-tr-state';

    static trackingComponentAttr = 'data-tr-component';

    /**
     * This enaum should be ongoingly maintained as new compoennt states are added.
     * The types should be also documented somewhere.
     * @TODO: Consider adding a variant with more than two mutual states.
     * @TODO: Consider exploring an additional `data-tr-value` to make state specific values available.
     * @type {Readonly<Object>}
     */
    static trackingStateEnum = Object.freeze({
        slideable: {
            active: 'Moved',
            inactive: 'Unmoved',
        },
        collapsible: {
            active: 'Expanded',
            inactive: 'Collapsed',
        },
    });

    /**
     * Expects a valid element and a style to set up the updating logic.
     * @param {$$element} target - The target element to track.
     * @param {string} type - The type of component to track.
     */
    constructor(target, type) {
        // Bail out if target and type are missing.
        if (!(target, type)) {
            throw new TypeError('AnalyticsHelper →  At least a target and a type to operate on are required.');
        }

        this.target = target;
        this.states = AnalyticsHelper.trackingStateEnum[type];

        // If no states were found we cannot operate.
        if (!this.states) {
            throw new TypeError(`AnalyticsHelper → No state informations were found for type: ${type}`);
        }

        // Always bind our methods to our class.
        this.getState = this.getState.bind(this);
        this.setState = this.setState.bind(this);
        this.unsetState = this.unsetState.bind(this);
    }

    /**
     * Returns the current tracking state of the component.
     */
    getState() {
        this.target.attr(AnalyticsHelper.trackingStateAttr);
    }

    /**
     * Sets the current tracking state of the component.
     */
    setState() {
        this.target.attr(AnalyticsHelper.trackingStateAttr, this.states.active);
    }

    /**
     * Unsets the current tracking state of the component.
     */
    unsetState() {
        this.target.attr(AnalyticsHelper.trackingStateAttr, this.states.inactive);
    }

}
