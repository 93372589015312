/* ------------------------------------*\
    #NOTIFICATION-CENTER SCRIPT
\*------------------------------------ */

import DropDown from '../../../../../src/js/components/drop-down';

class NotificationCenter extends DropDown {

    static componentName = 'notification-center';

    static componentSelector = '.js-notification-center';

    static defaultOptions = {
        btnSelector: '.js-notification-center__toggle',
        panelSelector: '.js-notification-center__panel',
        btnAction: 'toggle',
        btnWrapper: NotificationCenter.componentSelector,
    };

}

NotificationCenter.register();

export default NotificationCenter;
