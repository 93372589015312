/* ------------------------------------*\
    #COMPARE AND SELECT
\*------------------------------------ */

import { BasicSlider } from '../../../../../src/js/components/slider';

export default class CompareAndSelect extends BasicSlider {

    static componentName = 'compare-and-select';

    static componentSelector = '.js-compare-and-select';

    static defaultOptions = BasicSlider.extendDefaultOptions({
        preserveHeight: false,
        flickity: {
            cellSelector: '.js-compare-and-select__item',
            cellAlign: 'center',
            groupCells: '100%',
        },
    });

    constructor() {
        super(...arguments);
        const compareSelectAccordionBreakpointCustomProp = '--bron-compare-select-accordion-breakpoint';
        const accordions = Array.from(this.element.querySelectorAll('.js-compare-and-select__details'));
        const breakpoint = getComputedStyle(this.element).getPropertyValue(compareSelectAccordionBreakpointCustomProp);

        if (this.options.preserveHeight) {
            this.headers = Array.from(this.element.querySelectorAll('.c-compare-and-select__title')) ?? [];
            this.subheaders = Array.from(this.element.querySelectorAll('.c-compare-and-select__pricing')) ?? [];

            if (window.ResizeObserver) {
                this.ro = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        if (entry.target) {
                            this.#calculateHeightAndLines();
                        }
                    }
                });
                this.ro.observe(this.element);
            }
        }

        this.#calculateHeightAndLines();

        /**
         * Check if the {@link compareSelectAccordionBreakpointCustomProp} is present on the accordion element.
         * If so, listen to any mediaChanges and toggle the `[open]` attribute of the `<details>` element.
         * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
         */
        if (breakpoint) {
            const mediaQuery = window.matchMedia(`(min-width: ${breakpoint})`);
            const handleMediaChange = (ev) => {
                if (ev.matches) {
                    accordions.forEach((accordion) => {
                        accordion.setAttribute('open', true);
                    });
                } else {
                    accordions.forEach((accordion) => {
                        accordion.removeAttribute('open');
                    });
                }
            };

            if (mediaQuery.matches) {
                handleMediaChange({ matches: mediaQuery.matches });
            }

            mediaQuery.addEventListener('change', handleMediaChange);
        }

        /**
         * Check for nested mutations via {@link ContainerQuery} observer.
         * @type {MutationObserver}
         */
        const observer = new MutationObserver((mutationRecords) => {
            const nestedCQMutation = mutationRecords
                .filter((mr) => mr.type === 'attributes' && !(this.headers?.includes(mr.target) || this.subheaders?.includes(mr.target)))
                .find((mr) => mr.attributeName === 'data-container-initialized');
            /**
             * Recalculate the slider when a nested {@link ContainerQuery} mutation was observed.
             * If {@link this.options.preserveHeight} is set we skip it as it is already handled.
             */
            if (nestedCQMutation && !this.options.preserveHeight) {
                this.flickitySlider?.resize();
            }
        });

        /**
         * Observe the subtree for attribute mutations.
         */
        observer.observe(this.element, {
            attributes: true,
            subtree: true,
        });
    }

    /**
     * Handle the height and lines calculation.
     */
    #calculateHeightAndLines() {
        setTimeout(() => {
            /**
             * Reset the height for headers and subheaders prior to calculation.
             */
            this.headers?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', 'initial'));
            this.subheaders?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', 'initial'));

            /**
             * Infer the max lines and height for the headers and subheaders.
             */
            const [maxHeightTitle] = this.headers?.map((h) => h?.clientHeight).sort((a, b) => (a > b ? -1 : 1)) ?? [];
            const [maxHeightSubTitles] = this.subheaders?.map((h) => h?.clientHeight)
                .sort((a, b) => (a > b ? -1 : 1)) ?? [];
            if (maxHeightTitle) {
                this.headers?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', `${maxHeightTitle}px`));
            }
            if (maxHeightSubTitles) {
                this.subheaders?.forEach((h) => h?.style.setProperty('--bron-compare-and-select-min-height', `${maxHeightSubTitles}px`));
            }
            this.flickitySlider?.resize();
        }, 100);
    }

}

CompareAndSelect.register();
