/* ------------------------------------*\
    #NOTIFICATION SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';
import '../../../../../src/js/components/stacked-sticky';
import throttle from '../../../../../src/js/module/throttle';

export default class Notification extends PanelGroup {

    static componentName = 'notification';

    static componentSelector = '.c-notifications-group';

    static defaultOptions = {
        btnSelector: '.js-notification-close',
        panelSelector: '.js-notification-container',
        multiple: true,
        btnAction: 'collapse',
        defaultCollapsed: false,
        animateHeightClass: 'is-closed',
    };

    static updatePosition() {
        Notification.$$('.c-notifications-group').trigger('bronson_dimensionchange');
    }

    constructor() {
        super(...arguments);

        this.$$element.component('stacked-sticky');

        this.$$element.on('bronson_panelchanged', throttle(() => {
            this.trigger('dimensionchange');
        }, { that: this }));
    }

}

Notification.register();
