import logger from './logger';

/**
 * $$ module helps to work with collection of items - mostly DOM elements. $$ inherits all Array methods to work on the collection.
 * Additionally it adds the methods `call`, `set`, `get` to call methods and access properties on the child items
 * @module $$
 */

const arrayProto = Array.prototype;

/**
 *
 * @param elements {String|Array|ArrayLike} An element selector or an Array of items
 * @param [context] {Document|Element}
 * @returns {*|$$}
 *
 * @example
 * $$('.foo') // returns a $$ collection of DOM elements with class .foo
 *  .getAll('classList') // returns a $$ collection the classList objects of the above elements
 *  .callAll('add', 'bar', 'baz') // calls the add method of the classList objects, adds the classes 'bar' and 'baz' and due to the fact that classList.add returns undefined it returns the former $$ collection
 * ;
 *
 * $('.bar')
 *  // filter works on the hole collection as all Array methods. If an Array method returns a new Array a new $$ is generated with this array as input
 *  .filter(element => {
 *     return element.matches('ul');
 *  }) // return a new $$ of ul.bar elements
 *  // set works the same way as (call and get) and accesses/calls the properties of each item.
 *  .set('className', 'foo') // changes the class to 'foo' and returns the former $$ collection.
 * ;
 */
const $$ = function (_elements, context) {
    let elements = _elements;
    if (!(this instanceof $$)) {
        return new $$(elements);
    }

    if (typeof elements === 'string') {
        elements = Array.from((context || document).querySelectorAll(elements));
    } else if (typeof elements === 'function') {
        if ($$.isReady) {
            elements($$);
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                elements($$);
            });
        }

        return;
    }

    if (!Array.isArray(elements)) {
        if (!elements) {
            elements = [];
        } else if (elements.nodeName || !('length' in elements) || elements === window) {
            elements = [elements];
        } else {
            elements = Array.from(elements);
        }
    }

    this.items = elements;
    this.length = this.items.length || 0;
};

const fn = $$.prototype;

Object.assign($$, {
    fn,
    isReady: document.readyState !== 'loading',
    // eslint-disable-next-line default-param-last
    event(type, detail = {}, bubbles = true, cancelable) {
        const event = document.createEvent('Event');

        event.initEvent(type, bubbles, cancelable);

        event.detail = detail;

        return event;
    },
});

Object.assign(fn, {

    getAll(name) {
        return this.pushStack(this.items.map((item) => item[name]));
    },

    nth(index) {
        if (!this.items[index]) {
            logger.error(`No item at index ${index} found`);
        } else {
            return this.items[index];
        }
    },

    set(name, value) {
        const isObject = typeof name === 'object';

        this.items.forEach((item) => {
            if (isObject) {
                Object.assign(item, name);
            } else {
                item[name] = value;
            }
        });

        return this;
    },

    callAll(name, ...args) {
        const items = [];

        this.items.forEach((item) => {
            const value = item[name](...args);

            if (value !== undefined) {
                items.push(value);
            }
        });

        return items.length ? this.pushStack(items) : this;
    },

    getFirst(name) {
        let value;
        const [item] = this.items;

        if (item) {
            return item[name];
        }

        return value;
    },

    callFirst(name, ...args) {
        let value;
        const [item] = this.items;

        if (item) {
            return item[name](...args);
        }

        return value;
    },

    pushStack(items) {
        const ret = new $$(items);
        ret.prevObject = this;
        return ret;
    },

    eq(index) {
        return this.pushStack(this.items[index]);
    },

    end() {
        return this.prevObject || this.constructor();
    },

    trigger() {
        return this.items.forEach((item) => {
            const event = $$.event(...arguments);

            item.dispatchEvent(event);
        });
    },

    triggerFirst() {
        const event = $$.event(...arguments);
        const [item] = this.items;

        if (item) {
            item.dispatchEvent(event);
        }

        return event;
    },
});

Object.getOwnPropertyNames(arrayProto).forEach((name) => {
    if (!fn[name] && typeof arrayProto[name] === 'function') {
        fn[name] = function () {
            let ret = this;
            const result = this.items[name](...arguments);

            if (result !== undefined && this.items !== result) {
                if (Array.isArray(result)) {
                    ret = this.pushStack(result);
                } else {
                    ret = result;
                }
            }

            return ret;
        };
    }
});

if (!$$.isReady) {
    document.addEventListener('DOMContentLoaded', () => {
        $$.isReady = true;
    });
}

/* fn mapping */
/**
 * @memberOf $$.prototype
 * @name on
 */
/**
 * @memberOf $$.prototype
 * @name off
 */
[['on', 'addEventListener'], ['off', 'removeEventListener']].forEach(([simpleName, nativeName]) => {
    fn[simpleName] = function () {
        return this.callAll(nativeName, ...arguments);
    };
});

/* fn mapping to array */
/**
 * @memberOf $$.prototype
 * @name query
 */
[['query', 'querySelectorAll']].forEach(([simpleName, nativeName]) => {
    fn[simpleName] = function () {
        let ret;

        this.items.forEach((item) => {
            const value = item[nativeName](...arguments);

            if (value != null) {
                const returnValue = Array.from(value);

                if (returnValue.length) {
                    if (ret) {
                        returnValue.forEach((retItem) => {
                            if (!ret.includes(retItem)) {
                                ret.push(retItem);
                            }
                        });
                    } else {
                        ret = returnValue;
                    }
                }
            }
        });

        return this.pushStack(ret || []);
    };
});

/**
 * @memberOf $$.prototype
 * @name addClass
 */
/**
 * @memberOf $$.prototype
 * @name removeClass
 */
/**
 * @memberOf $$.prototype
 * @name toggleClass
 */
['add', 'remove', 'toggle'].forEach((fnName) => {
    const name = `${fnName}Class`;

    fn[name] = function () {
        this.items.forEach((item) => {
            item.classList[fnName](...arguments);
        });

        return this;
    };
});

fn.prop = fn.set;

fn.attr = function (name, value) {
    const type = typeof name;

    switch (type) {
        case 'string':
            if (typeof name === 'string') {
                if (arguments.length === 1) {
                    return this.call('getAttribute', name);
                }

                if (value == null) {
                    this.callAll('removeAttribute', name, value);
                } else {
                    this.callAll('setAttribute', name, value);
                }
            }
            break;
        case 'object':
            for (const prop in name) {
                if (name[prop] == null) {
                    this.callAll('removeAttribute', prop);
                } else {
                    this.callAll('setAttribute', prop, name[prop]);
                }
            }

            break;
        // no default
    }

    return this;
};

export default $$;
