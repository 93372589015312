import Component from '../module/component';
import './panel';
import './panelbutton';
import '../module/toggleAutoTransition';

export default class PanelGroup extends Component {

    static componentName = 'panelgroup';

    static componentSelector = '.js-panelgroup';

    static defaultOptions = {
        btnSelector: '.js-panelgroup__button',
        panelSelector: '.js-panelgroup__panel',
        multiple: false,
        btnAction: 'toggle',
        btnWrapper: '',
        btnWrapperClick: false,
        defaultSelectedIndex: -1,
        defaultCollapsed: true,
        checkNesting: false,
        animateHeightClass: '',
        panelToggleClass: '',
        panelClosedClass: '',
        parentToggleClass: '',
        panelChangeEvent: 'panelchanged',
        tabbedInterface: false,
    };

    static elementPlugins = [];

    static addElementPlugin(selectorDefinition, init) {
        Object.assign(PanelGroup.defaultOptions, selectorDefinition);
        Object.keys(selectorDefinition).forEach((selectorName) => {
            PanelGroup.elementPlugins.push({
                selectorName,
                init,
            });
        });
    }

    constructor() {
        super(...arguments);

        if (this.element.classList.contains('js-is-fx')) {
            this.options.animateHeightClass = 'is-closed';
        }

        this._initElements();

        requestAnimationFrame(() => {
            if (this.options.animateHeightClass) {
                this.element.classList.add('js-is-fx');
            }

            requestAnimationFrame(() => {
                this.$$element.addClass('js-is-ready');
            });
        });
    }

    _getPanelButtonOptions(index) {
        const {
            options: {
                btnAction,
                btnWrapper,
                btnWrapperClick,
                panelChangeEvent,
                tabbedInterface,
            },
        } = this;

        let target = this.$$panels.items[index];
        // If there's only one target but several triggers, trigger the first
        // target for all PanelButtons.
        // TODO: This is unsafe, triggers and panels should be mapped
        // explicitly instead of using the array index.
        if (target === undefined) {
            [target] = this.$$panels.items;
        }

        return {
            action: btnAction,
            btnWrapper,
            btnWrapperClick,
            target,
            panelChangeEvent,
            tabbedInterface,
        };
    }

    _getDefaultSelected() {
        const { defaultSelectedIndex } = this.options;
        let selectedIndex = this.$$buttons.findIndex((button) => button.matches('[aria-expanded="true"]'));

        if (selectedIndex === -1) {
            selectedIndex = this.$$panels.findIndex((panel) => panel.matches('[aria-hidden="false"]'));
        }
        if (selectedIndex === -1 && defaultSelectedIndex !== -1) {
            selectedIndex = defaultSelectedIndex;
        }

        return selectedIndex;
    }

    filterNesting($$items) {
        const { checkNesting } = this.options;

        if (checkNesting) {
            const nestingSelector =
                typeof checkNesting === 'string' ?
                    checkNesting :
                    this.constructor.componentSelector;

            return $$items.filter(
                (item) => item.closest(nestingSelector) === this.element,
            );
        }

        return $$items;
    }

    _initElements() {
        const {
            btnSelector,
            panelSelector,
            defaultCollapsed,
            animateHeightClass,
            panelToggleClass,
            panelClosedClass,
            panelChangeEvent,
            useInert,
        } = this.options;
        let defaultSelectedIndex;

        this.$$panels = this.filterNesting(this.query(panelSelector));
        this.$$buttons = this.filterNesting(this.query(btnSelector));

        if (defaultCollapsed) {
            defaultSelectedIndex = this._getDefaultSelected();
        }

        this.$$panels.component('panel', {
            animateHeightClass,
            toggleClass: panelToggleClass,
            closedClass: panelClosedClass,
            panelChangeEvent,
            useInert,
        });
        this.$$buttons.forEach((button, index) => {
            PanelGroup.$$(button).component(
                'panelbutton',
                this._getPanelButtonOptions(index),
            );
        });

        PanelGroup.elementPlugins.forEach((pluginDefinition) => {
            const selector =
                this.options[pluginDefinition.selectorName] ||
                PanelGroup.defaultOptions[pluginDefinition.selectorName];

            this.query(selector).forEach((element) => {
                pluginDefinition.init(element, this);
            });
        });

        this._initEvents();

        if (defaultSelectedIndex != null) {
            this.collapseAll(defaultSelectedIndex, true);
        }
    }

    _initEvents() {
        const { multiple, parentToggleClass } = this.options;

        if (!multiple) {
            this.$$panels.on(
                `bronson_${this.options.panelChangeEvent}`,
                ({ currentTarget }) => {
                    const isCollapsed = PanelGroup.$$(currentTarget)
                        .component('panel')
                        .getFirst('isCollapsed');

                    if (isCollapsed) {
                        return;
                    }

                    this._noIndexHandle = true;
                    this.collapseAll(currentTarget, false);
                    this._noIndexHandle = false;
                },
            );
        }

        if (parentToggleClass) {
            this.$$panels.on(`bronson_${this.options.panelChangeEvent}`, () => {
                this.$$element.toggleClass(parentToggleClass);
            });
        }
    }

    _getIndexFromElement(indexOrElement = -1) {
        let index = indexOrElement;

        if (typeof indexOrElement === 'object' && indexOrElement) {
            index = this.$$panels.findIndex(
                (panel) => indexOrElement === panel,
            );

            if (index === -1) {
                index = this.$$buttons.findIndex(
                    (button) => indexOrElement === button,
                );
            }
        }

        return index;
    }

    _callPanelMethod(elementOrIndex, ...args) {
        return this.$$panels
            .eq(this._getIndexFromElement(elementOrIndex))
            .component('panel')
            .callFirst(...args);
    }

    collapseAll(exceptIndexOrElement, noFx) {
        const noCollapseIndex = this._getIndexFromElement(exceptIndexOrElement);

        this.$$panels.component('panel').forEach((component, index) => {
            const isCollapsed = index !== noCollapseIndex;

            if (!this._noIndexHandle || isCollapsed) {
                component[isCollapsed ? 'collapse' : 'expand'](noFx);
            }
        });
    }

    expand(elementOrIndex, ...args) {
        this._callPanelMethod(elementOrIndex, 'expand', ...args);
    }

    collapse(elementOrIndex, ...args) {
        this._callPanelMethod(elementOrIndex, 'collapse', ...args);
    }

    toggle(elementOrIndex, ...args) {
        return this._callPanelMethod(elementOrIndex, 'toggle', ...args);
    }

    get selectedIndex() {
        return this.$$panels.findIndex(
            (panel) => !PanelGroup.$$(panel).component('panel').getFirst('isCollapsed'),
        );
    }

    set selectedIndex(index) {
        this._callPanelMethod(index, 'expand');
    }

}

PanelGroup.register();
