/* ------------------------------------*\
    COMBOBOX
\*------------------------------------ */

/*
 * Helper constants and functions
 */

// make it easier for ourselves by putting some values in objects
// in TypeScript, these would be enums
export const Keys = {
    Backspace: 'Backspace',
    Clear: 'Clear',
    Down: 'ArrowDown',
    End: 'End',
    Enter: 'Enter',
    Escape: 'Escape',
    Home: 'Home',
    Left: 'ArrowLeft',
    PageDown: 'PageDown',
    PageUp: 'PageUp',
    Right: 'ArrowRight',
    Space: ' ',
    Tab: 'Tab',
    Up: 'ArrowUp',
};

export const MenuActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    Previous: 6,
    Select: 7,
    Space: 8,
    Next10: 9,
    Previous10: 10,
    AssumedSearchInput: 11,
};

/**
 * Return combobox action from key press.
 * @param {String} key Key value from event object
 * @param {Boolean} menuOpen If opening menu should be triggered
 * @returns {Number} action to be triggered
 */
export function getActionFromKey(key, menuOpen) {
    // handle opening when closed
    if (!menuOpen && (
        (key === Keys.Down) ||
        (key === Keys.Enter) ||
        (key === Keys.Space))
    ) {
        return MenuActions.Open;
    }

    // handle keys when open
    if (key === Keys.Down) {
        return MenuActions.Next;
    } else if (key === Keys.Up) {
        return MenuActions.Previous;
    } else if (key === Keys.Home) {
        return MenuActions.First;
    } else if (key === Keys.End) {
        return MenuActions.Last;
    } else if (key === Keys.Escape) {
        return MenuActions.Close;
    } else if (key === Keys.Enter) {
        return MenuActions.CloseSelect;
    } else if (key === Keys.Space) {
        return MenuActions.Select;
    } else if (key === Keys.PageUp) {
        return MenuActions.Previous10;
    } else if (key === Keys.PageDown) {
        return MenuActions.Next10;
    }
    return MenuActions.AssumedSearchInput;
}

/**
 * Get valid index based on action, current and max index.
 * If the index exceeded available length, e.g. 18/20 from is selected
 * and PAGEDOWN is pressed, the max (20) gets returned.
 * @param {number} current current index
 * @param {number} length maximum available index
 * @param {number} action MenuAction enum
 * @returns
 */

export function getUpdatedIndex(current, length, action) {
    const last = length - 1;
    const isLast = (current === last);

    switch (action) {
        case MenuActions.First:
            return 0;
        case MenuActions.Last:
            return last;
        case MenuActions.Previous:
            return Math.max(current === 0 ? last : 0, current - 1);
        case MenuActions.Previous10:
            return Math.max(0, current - 10);
        case MenuActions.Next:
            return Math.min(isLast ? 0 : length, current + 1);
        case MenuActions.Next10:
            return Math.min(last, current + 10);
        default:
            return current;
    }
}

/**
 * Get values from selected objects and turn into comma-seperated string.
 * @param {Array} options List of objects with selected options
 * @returns {String} comma-seperated list of values
 */
export function getValueStringFromOptions(options) {
    return options
        .map((option) => option.value)
        .join();
}
