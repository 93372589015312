/* ------------------------------------*\
    #SMOOTH SCROLLING SCRIPT

    Add smooth scrolling
    to hooked-up selectors via data-attr
\*------------------------------------ */

import SmoothScroll from 'smooth-scroll';
import Component from './component';
import libs from './libs';

export default class Scroll extends Component {

    static componentName = 'scroll';

    static componentSelector = '[data-scroll], [data-scroll-section]';

    static init() {
        if (!SmoothScroll) {
            console.warn('WARNING: You are using "Back To Top" without its necessary dependency "SmoothScroll".');
            return;
        }
        // Smooth scroll uses event delegation so no need to re-init
        if (this.initialized) {
            return;
        }

        this.initialized = true;

        if (SmoothScroll) {
            this.topScroll = new SmoothScroll('[data-scroll]', {
                updateURL: false,
            });
            this.sectionScroll = new SmoothScroll('[data-scroll-section]');
        }
    }

}

Scroll.register();

if (SmoothScroll) {
    window.SmoothScroll = SmoothScroll;
    libs.SmoothScroll = SmoothScroll;
}
