/* ------------------------------------*\
    #SCROLL OBSERVER SCRIPT
\*------------------------------------ */

import Component from '../module/component';

export const SCROLL_OBSERVER_EVENT = 'ScrollObserverEvent';
export const SCROLL_OBSERVER_SKIP_OBSERVE_ATTR = 'data-scroll-observer-skip';

/**
 * @class ScrollObserver
 * The scroll observer tracks the intersecting of one target element with the viewport or
 * a specified root element given some threshold and rootMargins. If the assertions are met
 * classes are appended to the component element (not the tracked one). The observer can be
 * configured via data-attributes or via options passed into the constructor.
 *
 * @example
 * <div class="js-scroll-observer"
 *      data-scroll-observer-target="#my-target-to-observe"
 *      data-scroll-observer-threshold="1.0"
 * >
 *     <!-- Content here … -->
 * </div>
 */
export class ScrollObserver extends Component {

    static componentName = 'scroll-observer';

    static componentSelector = '.js-scroll-observer';

    /**
     * Default configuration for the ScrollObserver.
     * @property inViewportClass {string}
     * @property outsideViewportClass {string}
     * @property aboveEnterViewportClass {string}
     * @property aboveLeaveViewportClass {string}
     * @property belowEnterViewportClass {string}
     * @property belowLeaveViewportClass {string}
     * @property isIntersectingClass {string}
     * @property root {null|string}
     * @property rootMargin {string}
     * @property threshold {number|number[]}
     */
    static defaultOptions = {
        inViewportClass: 'has-target-inside-view',
        outsideViewportClass: 'has-target-outside-view',
        aboveEnterViewportClass: 'has-target-enter-above-view',
        aboveLeaveViewportClass: 'has-target-leave-above-view',
        belowEnterViewportClass: 'has-target-enter-below-view',
        belowLeaveViewportClass: 'has-target-leave-below-view',
        mostVisibleViewportAttribute: 'data-target-most-visible-view',
        /**
         * Setting proper defaults if data attributes are nullish.
         * @see https://w3c.github.io/IntersectionObserver/#dictdef-intersectionobserverinit
         */
        root: null,
        rootMargin: '0px',
        threshold: 0,
        allowMultiple: false,
    };

    constructor() {
        super(...arguments);
        const {
            target, root, rootMargin, threshold,
        } = this.options;

        /**
         * Guards against invalid configuration.
         */
        if (!target) {
            throw new TypeError('ScrollObserver → target must be a valid CSS selector!');
        }

        if (root !== null && !root) {
            throw new TypeError('ScrollObserver → root must be a valid CSS selector or null!');
        }

        /**
         * Gracefully degrade on older Browsers where
         * {@link IntersectionObserver} is not available.
         */
        if (window.IntersectionObserver) {
            this.observer = new IntersectionObserver(this.observeEntries.bind(this), {
                root: document.querySelector(root),
                rootMargin,
                threshold,
            });

            /**
             * Check if we deal with a collection or just a singleton item to track.
             */
            if (!this.options.trackMultiple) {
                this.target = document.querySelector(target);
                this.observer.observe(this.target);
            } else {
                this.target = document.querySelectorAll(target);
                this.target.forEach((t) => this.observer.observe(t));
                this.targets = Array.from(this.target);
            }
        }
    }

    /**
     * The method to call upon observing the {this.target}.
     * @param entries {HTMLElement[]} - An array of observed entries.
     */
    observeEntries(entries) {
        entries.forEach((entry) => {
            const { boundingClientRect: { top }, intersectionRect: { height } } = entry;
            entry.target.__intersectionHeight = height;

            if (!this.element.hasAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR)) {
                if (entry.isIntersecting) {
                    this.element.classList.remove(this.options.outsideViewportClass);
                    this.element.classList.add(this.options.inViewportClass);
                    if (this.lastScrollTop > top) {
                        this.element.classList.add(this.options.belowEnterViewportClass);
                        this.element.classList.remove(this.options.belowLeaveViewportClass);
                        this.element.classList.remove(this.options.aboveEnterViewportClass);
                    } else {
                        this.element.classList.add(this.options.aboveEnterViewportClass);
                        this.element.classList.remove(this.options.aboveLeaveViewportClass);
                        this.element.classList.remove(this.options.belowEnterViewportClass);
                    }
                } else {
                    this.element.classList.add(this.options.outsideViewportClass);
                    this.element.classList.remove(this.options.inViewportClass);
                    if (this.lastScrollTop > top) {
                        this.element.classList.add(this.options.aboveLeaveViewportClass);
                        this.element.classList.remove(this.options.aboveEnterViewportClass);
                        this.element.classList.remove(this.options.belowEnterViewportClass);
                    } else {
                        this.element.classList.add(this.options.belowLeaveViewportClass);
                        this.element.classList.remove(this.options.belowEnterViewportClass);
                        this.element.classList.remove(this.options.aboveEnterViewportClass);
                    }
                }
            }
            this.lastScrollTop = top;
        });

        if (this.options.trackMultiple) {
            this.mostVisibleEntry = this.targets
                .reduce(
                    (prev, curr) => (
                        (curr.__intersectionHeight > (prev?.__intersectionHeight ?? 0)) ? curr : prev),
                    null,
                );

            if (this.mostVisibleEntry) {
                this.element.setAttribute(this.options.mostVisibleViewportAttribute, `#${this.mostVisibleEntry?.id}`);
            }
        }

        /**
         * @type {ScrollObserverEvent}
         */
        const scrollObserverEvent = new CustomEvent(SCROLL_OBSERVER_EVENT, {
            detail: {
                intersectionObserverEntries: entries,
                mostVisibleEntry: this.mostVisibleEntry,
            },
        });
        if (!this.element.hasAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR)) {
            this.element.dispatchEvent(scrollObserverEvent);
        }
    }

    /**
     * Cancel the observation.
     */
    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

}

ScrollObserver.register();

export default ScrollObserver;
