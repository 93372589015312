import $$ from './item-collection';
import componentExpando from './component-expando';
import componentRegister from './component-register';

/**
 * Extends $$ to initialize and/or access BronsonComponents.
 * @param name {String}
 * @param initOptions
 * @returns {$$ of component instances}
 */
$$.fn.component = function (name, initOptions) {
    return this.pushStack(this.items.map((element) => {
        let componentsObject = element[componentExpando];

        if (!componentsObject) {
            componentsObject = {};
            element[componentExpando] = componentsObject;
        }

        if (!componentsObject[name]) {
            const ComponentClass = componentRegister.get(name);
            /* eslint-disable-next-line no-new */
            new ComponentClass(element, initOptions);
        }

        return componentsObject[name];
    }));
};
