/* ------------------------------------*\
    #WIZARD SCRIPT
\*------------------------------------ */

/**
 * In order to save some space, the wizard is turning into a dropdown on small
 * screen.
 */

import PanelGroup from '../../../../../src/js/components/panelgroup';

class Wizard extends PanelGroup {

    static componentName = 'wizard';

    static componentSelector = '.js-wizard';

    static defaultOptions = {
        btnSelector: '.js-wizard__trigger',
        panelSelector: '.js-wizard__list',
        btnAction: 'toggle',
        panelClosedClass: 'is-closed',
        useInert: false,
    };

}

Wizard.register();

export default Wizard;
