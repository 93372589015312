/* ------------------------------------*\
    #TREE VIEW SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';

/**
 * @class TreeView
 * @classdesc
 * Handles all interactions of a tree view component as specified via WAI-ARIA.
 * Sets up all ARIA attributes and landmark roles dynamically.
 */
class TreeView extends Component {

    static componentName = 'tree-view';

    static componentSelector = '.js-tree-view';

    static defaultOptions = {
        treeItemSelector: '.js-tree-view__item',
        treeItemHoverClass: 'is-hovered',
        treeItemSelectedClass: 'is-selected',
        treeItemDisabledClass: 'is-disabled',
        subtreeSelector: '.js-tree-view__group',
    };

    constructor() {
        super(...arguments);

        /**
         * Important class properties to use throughout the lifecycle.
         */
        this.$$subTree = this.$$element.query(this.options.subtreeSelector);
        this.$$treeItems = this.$$element.query(this.options.treeItemSelector);
        this.$$treeRoot = this.$$element;
        this.selectedTreeItem = null;

        /**
         * Enrich the tree elements with WAI-ARIA attributes.
         */
        this.setupAriaAttributes();

        /**
         * If no tree items were found we bail out.
         */
        if (!this.$$treeItems.items.length) {
            return;
        }

        /**
         * Make every tree items focusable.
         */
        this.$$treeItems.forEach((t) => t.setAttribute('tabindex', -1));
        this.$$treeItems.items[0].setAttribute('tabindex', 0);

        /**
         * Setup event handling for treeItem triggers.
         */
        this.$$treeItems.on('click', this.handleClick.bind(this));
        this.$$treeItems.on('keydown', this.handleKeyNavigation.bind(this));
        this.$$treeItems.on('focus', this.handleFocus.bind(this));
        this.$$treeItems.on('blur', this.handleBlur.bind(this));
        this.$$treeItems.on('mouseover', this.handleMouse.bind(this));
        this.$$treeItems.on('mouseout', this.handleMouse.bind(this));
    }

    /**
     * Setup all required ARIA attributes and landmark roles that are needed for
     * processign the tree view component.
     */
    setupAriaAttributes() {
        this.$$treeRoot.attr('role', 'tree');
        this.$$subTree.forEach((t) => t.setAttribute('role', 'group'));
        const nestedLists = this.$$treeRoot.query('[role=group]');

        /**
         * Iterate over each tree item node and setup its attributes accordingly.
         */
        this.$$treeItems.forEach((t) => {
            const siblings = t.parentElement.children;
            // We have to incrememnt since zero-based array position.
            const pos = [].indexOf.call(siblings, t) + 1;
            const subTree = t.querySelector('[role=group]');
            t.setAttribute('role', 'treeitem');
            t.setAttribute('aria-level', 1);
            t.setAttribute('aria-posinset', pos);
            t.setAttribute('aria-setsize', siblings.length);

            /**
             * Initially all nested groups are closed :-)
             */
            if (subTree) {
                t.setAttribute('aria-expanded', false);
            }

            /**
             * Calculate the nesting level and set it to root-level
             * if no nesting could be determined.
             */
            nestedLists.forEach((list, i) => {
                if (list.contains(t)) {
                    t.setAttribute('aria-level', i + 2);
                }
            });
        });
    }

    /**
     * Handle clicks on the tree item node.
     * [1] If a node is not expandable just bail out.
     * [2] Select the item in question.
     * [3] Toggle its state.
     * @param {Event} ev - The click event.
     */
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const { target } = ev;
        const treeItem = target.closest(this.options.treeItemSelector);

        if (!TreeView.isExpandable(treeItem) || TreeView.isDisabled(treeItem)) { /* [1] */
            // Silence is golden.
            return;
        }

        this.selectItem(treeItem); /* [2] */

        if (TreeView.isExpanded(treeItem)) { /* [3] */
            this.collapseItem();
        } else {
            this.expandItem();
        }
    }

    /**
     * Handle mouseover and mouseout.
     * Assigns/removes an `.is-hovered` class on interaction.
     * @param {Event} ev - The mouse event handle.
     */
    // eslint-disable-next-line class-methods-use-this
    handleMouse(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        const { target, type } = ev;
        const treeItem = target.closest(this.options.treeItemSelector);
        if (!TreeView.isDisabled(treeItem)) {
            if (type === 'mouseover') {
                treeItem.classList.add(this.options.treeItemHoverClass);
            } else if (type === 'mouseout') {
                treeItem.classList.remove(this.options.treeItemHoverClass);
            }
        }
    }

    /**
     * Handles high-level keyboard interactions and calls subsequent handlers.
     * @param ev
     */
    handleKeyNavigation(ev) {
        const { code } = ev;
        switch (code) {
            case ('ArrowLeft'): {
                ev.stopPropagation();
                ev.preventDefault();
                this.handleLeftKey();
                break;
            }
            case ('ArrowUp'): {
                ev.stopPropagation();
                ev.preventDefault();
                this.handleUpKey();
                break;
            }
            case ('ArrowRight'): {
                ev.stopPropagation();
                ev.preventDefault();
                this.handleRightKey();
                break;
            }
            case ('ArrowDown'): {
                ev.stopPropagation();
                ev.preventDefault();
                this.handleDownKey();
                break;
            }
            case ('Enter'): {
                this.handleEnterKey();
                break;
            }
            case ('PageDown'):
            case ('PageUp'):
            case ('Home'):
            case ('End'): {
                // @TODO TreeView → Handling this key should be implemented once multi-select is a thing.
                break;
            }
            default:
                // Silence is golden :-)
                break;
        }
    }

    /**
     * Collapse all expandable tree items.
     */
    collapseAll() {
        this.$$treeItems
            .filter((t) => !TreeView.isDisabled(t))
            .forEach((t) => t.setAttribute('aria-expanded', 'false'));
    }

    /**
     * Expand all expandable tree items.
     */
    expandAll() {
        this.$$treeItems
            .filter((t) => !TreeView.isDisabled(t))
            .forEach((t) => t.setAttribute('aria-expanded', 'true'));
    }

    /**
     * Collapse the selected item.
     */
    collapseItem() {
        if (!TreeView.isDisabled(this.selectedTreeItem)) {
            this.selectedTreeItem.setAttribute('aria-expanded', 'false');
        }
    }

    /**
     * Expand the selected item.
     */
    expandItem() {
        if (!TreeView.isDisabled(this.selectedTreeItem)) {
            this.selectedTreeItem.setAttribute('aria-expanded', 'true');
        }
    }

    /**
     * Handle selection of the passed item.
     * Upon selection all tree items are made non focusable and only the current
     * select is made focussable again. This is per spec for single-select tree views.
     * @param {HTMLElement} treeItem - The item to deselect.
     * @param {boolean} alignToTop - Whether the item should be scrolled into view from botton or top.
     */
    selectItem(treeItem, alignToTop = true) {
        if (treeItem) {
            this.$$treeItems.forEach((t) => t.setAttribute('tabindex', -1));
            this.selectedTreeItem = treeItem;
            this.selectedTreeItem.classList.add(this.options.treeItemSelectedClass);
            this.selectedTreeItem.setAttribute('tabindex', 0);

            if (!TreeView.isInViewport(this.selectedTreeItem)) {
                this.selectedTreeItem.scrollIntoView(alignToTop);
            }
        }
    }

    /**
     * Handle deselection of the passed item.
     * @param {HTMLElement} treeItem - The item to deselect.
     */
    deselectItem(treeItem) {
        if (treeItem) {
            treeItem.classList.remove(this.options.treeItemSelectedClass);
            treeItem.classList.remove(this.options.treeItemHoverClass);
            this.selectedTreeItem = null;
        }
    }

    /**
     * Select the next tree item sibling.
     * [1] If a sibling was found we select it.
     * [2] If no next sibling item was found we try to find the parents sibling element.
     * [3] Check if the parents first item was also expanded and select its first tree item.
     */
    selectNextSibling() {
        const sibling = this.selectedTreeItem.nextElementSibling;
        if (sibling) { /* [1] */
            this.deselectItem(this.selectedTreeItem);
            this.selectItem(sibling, false);
        } else { /* [2] */
            const parentListItem = this.selectedTreeItem.parentElement.parentElement;
            const parentListItemSibling = parentListItem.nextElementSibling;
            if (parentListItemSibling) { /* [3] */
                this.deselectItem(this.selectedTreeItem);
                this.selectItem(parentListItemSibling, false);
            } else {
                this.deselectItem(this.selectedTreeItem);
                this.selectItem(parentListItem, false);
                this.selectNextSibling();
            }
        }
    }

    /**
     * Select the previous tree item sibling.
     * [1] If a sibling was found we need to select the last item of the last open tree item group.
     * [2] Otherwise we select the parent tree item if any.
     */
    selectPreviousSibling() {
        const sibling = this.selectedTreeItem.previousElementSibling;
        if (sibling) { /* [1] */
            if (TreeView.isExpanded(sibling)) {
                const { treeItemSelector, subtreeSelector } = this.options;
                const lastChildItemSelector = `[aria-expanded=true] > ${subtreeSelector} >  ${treeItemSelector}:last-child`;
                const lastChildItems = sibling.querySelectorAll(lastChildItemSelector);
                const lastChildItem = lastChildItems[lastChildItems.length - 1];
                if (lastChildItem) {
                    this.deselectItem(this.selectedTreeItem);
                    this.selectItem(lastChildItem);
                }
            } else {
                this.deselectItem(this.selectedTreeItem);
                this.selectItem(sibling);
            }
        } else { /* [2] */
            this.selectParent();
        }
    }

    /**
     * Select the parent tree item.
     */
    selectParent() {
        const parentListItem = this.selectedTreeItem.parentElement.parentElement;
        if (parentListItem && !TreeView.isRootLevel(this.selectedTreeItem)) {
            this.deselectItem(this.selectedTreeItem);
            this.selectItem(parentListItem);
        }
    }

    /**
     * Select first child item.
     */
    selectFirstChild() {
        const { treeItemSelector, subtreeSelector } = this.options;
        const firstChildItemSelector = `${subtreeSelector} ${treeItemSelector}:first-child`;
        const firstChildItem = this.selectedTreeItem.querySelector(firstChildItemSelector);
        if (firstChildItem) {
            this.deselectItem(this.selectedTreeItem);
            this.selectItem(firstChildItem);
        }
    }

    /**
     * Select last child item.
     */
    selectLastChild() {
        const { treeItemSelector, subtreeSelector } = this.options;
        const lastChildItemSelector = `${subtreeSelector} ${treeItemSelector}:last-child`;
        const lastChildItem = this.selectedTreeItem.querySelector(lastChildItemSelector);
        if (lastChildItem) {
            this.deselectItem(this.selectedTreeItem);
            this.selectItem(lastChildItem, false);
        }
    }

    /**
     * Handle focus on a tree item.
     * [1] If no target was found bail out.
     * [2] Only focus interactive tree items.
     * @param {HTMLElement} target - The target which received focus.
     */
    handleFocus({ target }) {
        if (!target) { /* [1] */
            return;
        }

        const treeItem = target.closest(this.options.treeItemSelector);

        if (!TreeView.isExpanded(treeItem)) {
            this.selectItem(treeItem); /* [2] */
        }
    }

    /**
     * Handle blur events on focused tree item nodes.
     * If the blurred target is not the current tree item, deselect it.
     * @param target
     */
    handleBlur({ target }) {
        if (!target) {
            return;
        }
        const treeItem = target.closest(this.options.treeItemSelector);
        if (document.activeElement !== treeItem) {
            this.deselectItem(treeItem);
        }
    }

    /**
     * Handles down arrow key interaction.
     * [1] Guard against root level tree item leaving its parent.
     * [2] If the selected tree item is expanded select its first tree item child,
     *     otherwise select its next sibling if any.
     */
    handleDownKey() {
        if (TreeView.isRootLevel(this.selectedTreeItem) && !this.selectedTreeItem.nextElementSibling) {
            return; /* [1] */
        }
        if (TreeView.isExpanded(this.selectedTreeItem)) {
            this.selectFirstChild();
        } else {
            this.selectNextSibling();
        }
        if (!TreeView.isInViewport(this.selectedTreeItem)) {
            this.selectedTreeItem.scrollIntoView(false);
        }
    }

    /**
     * Handle enter key interaction.
     * Check if the selected tree item is selectable.
     * If so, collapse or expand as per state of the tree item.
     */
    handleEnterKey() {
        if (TreeView.isExpandable(this.selectedTreeItem)) {
            if (TreeView.isExpanded(this.selectedTreeItem)) {
                this.collapseItem();
            } else {
                this.expandItem();
            }
        }
    }

    /**
     * Handles up arrow key interaction.
     * If the selected tree item is the first child tree item select its parent.
     * If the parent itself is a tree item with a nested list select the last visible item.
     */
    handleUpKey() {
        if (this.selectedTreeItem.previousElementSibling) {
            this.selectPreviousSibling();
        } else if (!TreeView.isRootLevel(this.selectedTreeItem)) {
            this.selectParent();
        }
        if (!TreeView.isInViewport(this.selectedTreeItem)) {
            this.selectedTreeItem.scrollIntoView(true);
        }
    }

    /**
     * Handles right arrow key interaction.
     * If the selected tree item is expanded select it first child tree item,
     * otherwise expand the selected tree item if it is expandable.
     */
    handleRightKey() {
        if (TreeView.isExpandable(this.selectedTreeItem)) {
            if (TreeView.isExpanded(this.selectedTreeItem)) {
                this.selectFirstChild();
            } else {
                this.expandItem();
            }
        }
    }

    /**
     * Handles left arrow key interaction.
     * If the selected tree item is expanded collapse it,
     * otherwise select the parent if not on root level.
     */
    handleLeftKey() {
        if (TreeView.isExpanded(this.selectedTreeItem)) {
            this.collapseItem();
        } else {
            this.selectParent();
        }
        if (!TreeView.isInViewport(this.selectedTreeItem)) {
            this.selectedTreeItem.scrollIntoView(true);
        }
    }

    /**
     * Check if a tree item is on the root level.
     * @static
     * @param {HTMLElement} item
     * @return {boolean}
     */
    static isRootLevel(item) {
        return item && item.getAttribute('aria-level') === '1';
    }

    /**
     * Check if a tree item is expanded.
     * @static
     * @param {HTMLElement} item
     * @return {boolean}
     */
    static isExpanded(item) {
        return item && item.getAttribute('aria-expanded') === 'true';
    }

    /**
     * Check if a tree item is expandable.
     * @static
     * @param {HTMLElement} item
     * @return {boolean}
     */
    static isExpandable(item) {
        return item && item.hasAttribute('aria-expanded');
    }

    /**
     * Check if a tree item is disabled.
     * @static
     * @param {HTMLElement} item
     * @return {boolean}
     */
    static isDisabled(item) {
        return item && item.classList.contains(TreeView.defaultOptions.treeItemDisabledClass);
    }

    /**
     * Check if a tree item is inside the visible viewport.
     * @param item
     * @return {boolean|boolean}
     */
    static isInViewport(item) {
        const { top, left, bottom } = item && item.getBoundingClientRect();
        const { innerWidth, innerHeight } = window;

        return (
            top >= 0 &&
            left >= 0 &&
            left <= innerWidth &&
            bottom <= innerHeight
        );
    }

}

TreeView.register();

export default TreeView;
