/* eslint-disable class-methods-use-this */
import Component from './component';

export class StickyElementSize extends Component {

    static componentName = 'resize-observer';

    // Adding individual component selectors to keep the markup clean
    // and have deliberate control.
    static componentSelector = `
        .c-floating-bar
    `;

    static defaultOptions = {
    };

    constructor() {
        super(...arguments);

        /**
         * Gracefully degrade on older browsers where
         * {@link ResizeObserver} is not available.
         */
        if (window.ResizeObserver) {
            this.observer = new ResizeObserver(this.observeEntries.bind(this));
            this.observer.observe(this.element);
        }
    }

    /**
     * The method to call upon observing the {this.target}.
     * @param entries {ResizeObserverEntry[]} - An array of observed entries.
     */
    observeEntries(entries) {
        // This array only holds one element on initialization.
        // More elements only if added via `this.observe`.
        entries
            .forEach((entry) => {
                this.setOffsetHeight(entry.target);
            });
    }

    setOffsetHeight(target) {
        document.documentElement.style.setProperty('--bron-sticky-offset', `${target.offsetHeight}px`);
    }

}

StickyElementSize.register();

export default StickyElementSize;
