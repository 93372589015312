/**
 *
 * @param fn {Function}
 * @param [options] {object}
 * @param [options.delay=200] {number}
 * @param [options.that] {object}
 * @returns {Function}
 */
export default function throttle(fn, options) {
    let running;
    let that;
    let args;

    let lastTime = 0;

    const run = () => {
        running = false;
        lastTime = Date.now();
        fn.apply(options?.that || that, args);
    };

    const opts = { delay: 200, ...options };

    return function () {
        that = this;
        args = arguments;

        if (running) {
            return;
        }

        let delay = opts.delay - (Date.now() - lastTime);

        if (delay < 0) {
            delay = 0;
        }

        running = true;

        setTimeout(run, delay);
    };
}
