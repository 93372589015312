/* ------------------------------------*\
    #FORM SECTION SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

class FormSection extends PanelGroup {

    static componentName = 'form-section';

    static componentSelector = '.js-form-section';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.js-form-section__header',
        panelSelector: '.js-form-section__panel',
        btnAction: 'toggle',
        parentToggleClass: 'is-open',
    });

}

FormSection.register();

export default FormSection;
