import Component from '../module/component';

export default class Panel extends Component {

    static componentName = 'panel';

    static componentSelector = '.js-panel';

    /**
     * Default configuration for Panel.
     * @property {string} animateHeightClass
     * @property {string} toggleClass
     * @property {string} closedClass
     * @property {string} panelChangeEvent
     * @property {string} panelStateCollapsedClass
     * @property {string} panelStateCollapsingClass
     * @property {string} panelStateExpandedClass
     * @property {string} panelStateExpandingClass
     */
    static defaultOptions = {
        animateHeightClass: '',
        toggleClass: '',
        closedClass: '',
        useInert: true,
        panelChangeEvent: 'panelchanged',
        panelStateCollapsedClass: 'is-collapsed',
        panelStateCollapsingClass: 'is-collapsing',
        panelStateExpandedClass: 'is-expanded',
        panelStateExpandingClass: 'is-expanding',
    };

    constructor() {
        super(...arguments);

        this.noFx = true;

        const { options } = this;

        this.panelStateClasses = [
            options.panelStateCollapsedClass,
            options.panelStateCollapsingClass,
            options.panelStateExpandedClass,
            options.panelStateExpandingClass,
        ];

        if (this.element.classList.contains('js-is-fx')) {
            options.animateHeightClass = 'is-closed';
        }

        if (options.closedClass) {
            options.toggleClass = options.closedClass;
        }

        requestAnimationFrame(() => {
            const {
                animateHeightClass,
                toggleClass,
                panelStateCollapsedClass,
                panelStateCollapsingClass,
                panelStateExpandedClass,
                panelStateExpandingClass,
            } = options;

            /**
             * Check if the animation is handled via JS or CSS.
             */
            if (animateHeightClass) {
                this.element.classList.add('js-is-fx');
            } else {
                this.element.addEventListener('animationstart', () => {
                    this.element.classList.remove(...this.panelStateClasses);

                    /**
                     * Check if the accordion panel is collapsing or expanding
                     * when the animation starts.
                     */
                    if (this.element.getAttribute('aria-hidden') === 'true') {
                        this.element.classList.add(panelStateCollapsingClass);
                    } else {
                        this.element.classList.add(panelStateExpandingClass);
                    }
                });

                this.element.addEventListener('animationend', () => {
                    this.element.classList.remove(...this.panelStateClasses);

                    /**
                     * Check if the accordion panel is collapsed or expanded
                     * when the animation is finished.
                     */
                    if (this.element.getAttribute('aria-hidden') === 'true') {
                        this.element.classList.add(panelStateCollapsedClass);
                    } else {
                        this.element.classList.add(panelStateExpandedClass);
                    }
                });
            }

            if (this.isCollapsed && (animateHeightClass || toggleClass)) {
                this.collapse(true);
            }

            requestAnimationFrame(() => {
                this.noFx = false;
                this.$$element.addClass('js-is-ready');
            });
        });
    }

    _change(hide, noFx = this.noFx) {
        const {
            animateHeightClass,
            toggleClass,
            closedClass,
            panelStateCollapsedClass,
            panelStateCollapsingClass,
            panelStateExpandedClass,
            panelStateExpandingClass,
            useInert,
        } = this.options;

        const stateChange = this.isCollapsed !== hide;

        /**
          * Toggle `inert` attribute to prevent user interactions
          * when the panel is collapsed.
          */
        if (useInert) {
            if (hide) {
                this.element.setAttribute('inert', '');
            } else {
                this.element.removeAttribute('inert');
            }
        }

        if (!closedClass) {
            this.$$element.attr('aria-hidden', hide);
        }

        if (toggleClass) {
            this.$$element.toggleClass(toggleClass, hide);
        }

        if (animateHeightClass && this.element.classList.contains(animateHeightClass) !== hide) {
            const heightValue = hide ? 0 : 'auto';

            this.$$element.toggleHeightTransition(
                heightValue,
                {
                    className: animateHeightClass,
                    force: hide,
                    noFx,
                },
                /**
             * This callback is triggered when the animation starts.
             * @param {HTMLElement} element
             */ (element) => {
                    element.classList.remove(...this.panelStateClasses);
                    element.style.setProperty('display', '');

                    /**
                 * Check if the accordion panel is collapsing or expanding.
                 */
                    if (!heightValue) {
                        element.classList.add(panelStateCollapsingClass);
                    } else {
                        element.classList.add(panelStateExpandingClass);
                    }
                },
                /**
             * This callback is triggered when the animation is finished.
             * @param {HTMLElement} element
             */ (element) => {
                    element.classList.remove(...this.panelStateClasses);

                    /**
                 * Check if the accordion panel is collapsed or expanded.
                 */
                    if (!heightValue) {
                        element.style.setProperty('display', 'none');
                        element.classList.add(panelStateCollapsedClass);
                    } else {
                        element.style.setProperty('display', '');
                        element.classList.add(panelStateExpandedClass);
                    }
                },
            );
        }

        if (stateChange) {
            this.trigger(this.options.panelChangeEvent);
        }
    }

    expand(noFx) {
        this._change(false, noFx);
    }

    collapse(noFx) {
        this._change(true, noFx);
    }

    toggle(noFx) {
        this._change(!this.isCollapsed, noFx);

        return this.isCollapsed;
    }

    get isCollapsed() {
        const { closedClass } = this.options;
        return closedClass ?
            this.element.classList.contains(closedClass) :
            this.element.getAttribute('aria-hidden') === String(true);
    }

    set isCollapsed(shouldCollapse) {
        this._change(shouldCollapse);
    }

}

Panel.register();
