/* ------------------------------------*\
    #INTERACTION-LAYER SCRIPT
\*------------------------------------ */

// import Overlay from '@vwfs-bronson/bronson-default/src/js/components/overlay';
import Overlay from '../../../../../src/js/components/overlay';

/**
 * The interaction layer is a specific overlay component that obscures content while not acting modally.
 * @class InteractionLayer
 * @extends Overlay
 */
export default class InteractionLayer extends Overlay {

    static componentName = 'interaction-layer';

    static componentSelector = '.js-interaction-layer';

    // EXTEND DEFAULTS
    static defaultOptions = InteractionLayer.extendDefaultOptions({
        componentName: InteractionLayer.componentName,
        triggerSelector: '.js-interaction-layer-trigger',
        closeSelector: '.js-interaction-layer-close',
        autofocus: '.js-interaction-layer-autofocus',
        openEventName: 'bronson_interaction-layer_open',
        closeEventName: 'bronson_interaction-layer_close',
        closeOnBackdrop: false,
    });

    constructor() {
        super(...arguments);

        /**
         * Hide the interaction layer when clicking on the backdrop.
         */
        if (
            this.element.classList.contains('c-interaction-layer--modal') &&
            this.options.closeOnBackdrop
        ) {
            this.element.addEventListener('click', (event) => {
                /**
                 * Check if we only hit the wrapper
                 * and the interaction layer is visible and close it.
                 */
                if (
                    this.element.getAttribute('aria-hidden') === 'false' &&
                    event.target.matches(InteractionLayer.componentSelector)
                ) {
                    this.close();
                }
            });
        }
    }

}

InteractionLayer.register();
