/* ------------------------------------*\
    #MODAL SCRIPT
\*------------------------------------ */

import Overlay from '../../../../src/js/components/overlay';

/**
 * The modal is a specific overlay component that obscures content and prompts the user for an input.
 * The modal also extends the overlay functionality to handle video input.
 * The modal behavior is set in CSS.
 * TODO: Discuss if the video functionality should be hoisted into the Overlay superclass.
 * @class Modal
 * @extends Overlay
 */
export default class Modal extends Overlay {

    static componentName = 'modal';

    static componentSelector = '.c-modal';

    static defaultOptions = Modal.extendDefaultOptions({
        componentName: Modal.componentName,
        triggerSelector: '.js-modal-trigger',
        closeSelector: '.js-modal-close',
        autofocus: '.js-modal-autofocus',
        openEventName: 'bronson_modal_open',
        closeEventName: 'bronson_modal_close',
    });

    constructor() {
        super(...arguments);

        this.initVideo();
    }

    /**
     * Initialize events for video playback
     */
    initVideo() {
        const $video = this.element.querySelector('.js-modal-video');

        // do nothing if video element does not exist in DOM
        if (!$video) {
            return;
        }

        // check for autoplay before initializing video module
        // as the element gets rebuild after video init
        const autoplay = !!($video.dataset.videoCustomAutoplay || $video.dataset.videoCustomAutoplay === '');

        // init video module
        this.video = bronson.$$($video).component('video').items[0]; // eslint-disable-line prefer-destructuring
        this.video.autoplay = autoplay;

        this.$$element.on(this.options.openEventName, () => {
            this.toggleVideoPlay(true);
        });

        this.$$element.on(this.options.closeEventName, () => {
            this.toggleVideoPlay(false);
        });
    }

    /**
     * Video playback control depending on state
     * @param {Boolean} state true: play, false: pause
     */
    toggleVideoPlay(state) {
        if (state && this.video.autoplay) {
            this.video.plyr.togglePlay(state);
        }

        if (!state) {
            this.video.plyr.togglePlay(state);
        }
    }

}

Modal.register();
