/* ------------------------------------*\
    #DATEPICKER SCRIPT
\*------------------------------------ */

import flatpickr from 'flatpickr/dist/flatpickr'; // use non-esm
import Component from '../../../../../src/js/module/component';
import libs from '../../../../../src/js/module/libs';

// Import the German locale. English is the default locale.
import 'flatpickr/dist/l10n/de';

export default class Datepicker extends Component {

    static componentName = 'datepicker';

    static componentSelector = '.js-datepicker';

    static flatpickr = flatpickr;

    static defaultOptions = {
        allowInput: true,
        dateFormat: 'd.m.Y',
        nextArrow: '<i class="c-icon c-icon--[semantic-forward] c-icon-–simple"></i>',
        prevArrow: '<i class="c-icon c-icon--[semantic-back] c-icon-–simple"></i>',
        wrap: true,
    };

    constructor() {
        super(...arguments);

        if (!flatpickr) {
            console.warn('WARNING: You are using "Datepicker" without its necessary dependency "flatpickr".');
            return;
        }

        // Array of flatpickr event hooks. For detailed information see https://flatpickr.js.org/events/.
        const flatpickrEventHooks = [
            'onChange',
            'onClose',
            'onOpen',
            'onMonthChange',
            'onYearChange',
            'onReady',
            'onValueUpdate',
            'onDayCreate',
        ];

        // For each flatpickr event hook, we dispatch a Bronson-specific event. The names
        // of these events start with "bronson_datepicker" and end with the name of the
        // corresponding flatpickr event (without "on"). So "onOpen" will dispatch the
        // event "bronson_datepickerOpen".
        flatpickrEventHooks.forEach((type) => {
            const that = this;

            this.options[type] = (...args) => {
                that.trigger(type.replace(/^on/, that.constructor.componentName), { args });
            };
        });

        // Fix issue on mobile devices when "readonly" attribute is used for the input
        // field. If we do not set "disableMobile = true", the user can open the native
        // datepicker by clicking on the input field.
        const isReadOnly = this.query('.c-input__input').getFirst('readOnly');

        if (isReadOnly) {
            this.options.disableMobile = true;
        }

        this.flatpickr = flatpickr(this.element, this.options);
    }

}

Datepicker.register();

if (flatpickr) {
    libs.flatpickr = flatpickr;
}
