/* ------------------------------------*\
    #TABLE STICKY SCRIPT
\*------------------------------------ */

import Component from './component';

/**
 * Add classes to tables when head row cells or first column cells are sticky.
 * @class TableSticky
 * @extends Component
 */

export default class TableSticky extends Component {

    static componentName = 'tables-sticky';

    static componentSelector = '.c-table--sticky-head, .c-table--sticky-column';

    static defaultOptions = {
        hasStickyHeadClass: 'has-sticky-head', // The class to add to the table wrapper when a head row cell is sticky
        hasStickyColumnClass: 'has-sticky-column', // The class to add to the table wrapper when a first column cell is sticky
        observerOptions: {
            threshold: [1], // The threshold for the IntersectionObserver (1 means the element is fully in view)
        },
    };

    /**
     * Detects when head row cells or first column cells become sticky and adds classes to the table wrapper.
     */
    tableSticky() {
        try {
            const tableWrapper = this.element; // Get the table wrapper element

            // STICKY HEADER ROW

            // Cells in thead that could become sticky
            const headCells = Array.from(tableWrapper.querySelectorAll('thead tr > *'));

            // Observe head cells
            headCells.forEach((headCell) => {
                new IntersectionObserver(
                    (entries) => {
                        entries.forEach((cell) => {
                            // Top position of the table wrapper.
                            const tableWrapperTop = tableWrapper.getBoundingClientRect().top;

                            // Top position of the cell.
                            const cellTop = cell.boundingClientRect.top;

                            // Detect that the cell is sticky when its top boundary is above the viewport.
                            let isSticky = cellTop < 0;

                            // Detect that the cell is sticky when the table wrapper top boundary is above the (sticky) cell.
                            // This is the case when the table does not stick to the top edge of the viewport, but to another container.
                            if (tableWrapperTop - cellTop < 0) {
                                isSticky = tableWrapperTop - cellTop < 0;
                            }

                            // Add class to table wrapper when cell is sticky
                            tableWrapper.classList.toggle(
                                this.options.hasStickyHeadClass,
                                isSticky,
                            );
                        });
                    },
                    this.options.observerOptions,
                ).observe(headCell);
            });

            // STICKY FIRST COLUMN

            // Cells in the first column that could become sticky
            const firstColumnCells = Array.from(tableWrapper.querySelectorAll('tr > :first-child'));

            // Observe first column cells
            firstColumnCells.forEach((firstColumnCell) => {
                new IntersectionObserver(
                    (entries) => {
                        entries.forEach((cell) => {
                            // Left position of the table wrapper.
                            const tableWrapperLeft = tableWrapper.getBoundingClientRect().left;

                            // Left position of the cell.
                            const cellLeftPos = cell.boundingClientRect.left;

                            // If the table wrapper or `table` have a left margin, we need to calculate with it.
                            const tableWrapperMarginLeft = parseFloat(window.getComputedStyle(tableWrapper).marginLeft);
                            const tableMarginLeft = parseFloat(window.getComputedStyle(tableWrapper.querySelector('table')).marginLeft);
                            const marginLeft = tableWrapperMarginLeft + tableMarginLeft;

                            // Detect that the cell is sticky when its left boundary is less than 0px from the left border of the viewport.
                            let isSticky = cellLeftPos < 0;

                            // Detect that the cell is sticky when the table wrapper (+ table margin) is further left than the cell.
                            // This is the case when the table does not stick to the left edge of the viewport, but to another container.
                            isSticky = tableWrapperLeft + marginLeft - cellLeftPos < 0 ? true : isSticky;

                            // Add class to table wrapper when cell is sticky
                            tableWrapper.classList.toggle(
                                this.options.hasStickyColumnClass,
                                isSticky,
                            );
                        });
                    },
                    this.options.observerOptions,
                ).observe(firstColumnCell);
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`tableSticky(): ${e}`);
        }
    }

    constructor() {
        super(...arguments);
        this.tableSticky();
    }

}

TableSticky.register();
