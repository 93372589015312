/* ------------------------------------*\
    #FLOAT GRID SCRIPT
\*------------------------------------ */

const floatGridElementId = 'float-grid';
const floatGridElementClass = 'o-float-grid';
const floatGridAreaClass = 'o-float-grid__area';
const floatGridAreaDataAttribute = 'data-float-grid-area';
const floatGridAreasCssCustomProperty = '--float-grid-areas';
const floatGridSelectorsCssCustomProperty = '--float-grid-selectors';
const floatGridAreaCssCustomProperty = '--float-grid-area';
const mutationObserverEnableSelector = '.js-enable-3rd-party-float-grid';
const mutationObserverTargetNode = 'body';

// Get grid areas from CSS Custom Property `--float-grid-areas` from `:root` and split values into array
const floatGridAreas = getComputedStyle(document.documentElement)
    .getPropertyValue(floatGridAreasCssCustomProperty)
    .trim()
    .split(' ');

// Create Float Grid after initial HTML document has been completely loaded and parsed
// (to avoid errors when JS is loaded and executed before rendering the DOM)
document.addEventListener('DOMContentLoaded', () => {
    /**
     * Create Float Grid markup
     */
    (function () {
        // Float Grid object
        const floatListElement = document.createElement('div');
        floatListElement.id = floatGridElementId;
        floatListElement.className = floatGridElementClass;
        document.body.prepend(floatListElement);

        // Float Grid areas
        floatGridAreas.forEach((area) => {
            floatListElement
                .insertAdjacentHTML('beforeend', `<div class="${floatGridAreaClass}"  ${floatGridAreaDataAttribute}="${area}"></div>`);
        });
    }());

    /**
     * Check CSS selector allowed characters
     * @param {String} selector - CSS selector
     */
    function checkCssSelectorAllowedCharacters(selector) {
        const cssSelectorAllowedCharacters = /[^#.\\>+~[\]:()*-_@0-9a-zA-Z\s]+/;
        if (cssSelectorAllowedCharacters.test(selector)) {
            throw new TypeError(`Float Grid: Invalid characters in CSS selector ${selector}`);
        }
        return true;
    }

    // Get CSS custom property `--float-grid-selectors` that contains all element selectors as value
    const floatGridElementsSelectorsList = getComputedStyle(document.getElementById(floatGridElementId))
        .getPropertyValue(floatGridSelectorsCssCustomProperty)
        .trim();

    // Sanitize each selector of `floatGridElementsSelectorsList`
    const floatGridElementsSelectorsListSanitized = floatGridElementsSelectorsList
        .split(',')
        .filter(Boolean) // Remove empty values from array
        .concat('.sg-float-grid-item') // Add selector for styleguide demo items
        .map((selector) => selector.trim())
        .filter(checkCssSelectorAllowedCharacters)
        .join(', ');

    /**
     * Check elements if they should be moved to a Float Grid area and move them.
     * @param {Element, Node} element - The element that should be moved into a Float Grid area
     */
    function moveElementToGridArea(element) {
        const style = window.getComputedStyle(element);
        const area = style.getPropertyValue(floatGridAreaCssCustomProperty).trim();

        // If area is set and area does not have the value `false`
        // (which is used for children of the element)
        if (area && area !== 'false') {
            const floatGridAreaSelector = `[${floatGridAreaDataAttribute}="${area}"]`;
            const floatGridAreaElement = document.querySelector(floatGridAreaSelector);

            if (floatGridAreaElement) {
                // Move the element to target Float Grid area
                floatGridAreaElement?.append(element);
            } else {
                // Throw error if the area is not found
                throw new TypeError(`Float Grid: Area ${floatGridAreaSelector} does not exist.`);
            }
        }
    }

    /**
     * Query elements that should be moved to a Float Grid area
     */
    (function () {
        if (floatGridElementsSelectorsListSanitized) {
            // Query all selectors and pass each element to `moveElementToGridArea` function
            document.querySelectorAll(floatGridElementsSelectorsListSanitized)
                .forEach((element) => moveElementToGridArea(element));
        }
    }());

    /**
     * Observe the DOM for changed elements that should be moved to a Float Grid area
     */
    (function () {
        // Observe `body` for mutations
        const targetNode = document.querySelector(mutationObserverTargetNode);

        // Options for the observer (which mutations to observe)
        const config = {
            childList: true,
            subtree: true,
        };

        // Callback function to execute when mutations are observed
        const callback = function (mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    // A single node
                    const [node] = mutation.addedNodes;

                    // If node type is `Element` (1) and node is not already in `['data-float-grid-area']`
                    if (node?.nodeType === 1 && !node?.parentElement?.attributes[floatGridAreaDataAttribute]) {
                        moveElementToGridArea(node);
                    }
                }
            });
        };

        // Only use mutation observer if the class `js-enable-3rd-party-float-grid` is present
        if (document.querySelector(mutationObserverEnableSelector)) {
            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(callback);

            // Start observing the target node for configured mutations
            observer.observe(targetNode, config);
        }
    }());
});
