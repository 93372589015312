/* ------------------------------------*\
    #CONTEXT NOTIFICATION SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

export default class ContextNotification extends PanelGroup {

    static componentName = 'contextNotification';

    static componentSelector = '.js-context-notification';

    static defaultOptions = {
        btnSelector: '.js-notification-close',
        panelSelector: '.js-notification-container',
        btnAction: 'collapse',
        defaultCollapsed: false,
        animateHeightClass: 'is-closed',
    };

}

ContextNotification.register();
