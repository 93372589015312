import Component from '../module/component';
import './panel';

export default class PanelButton extends Component {

    static componentName = 'panelbutton';

    static componentSelector = '.js-panelbutton';

    static defaultOptions = {
        target: '',
        action: 'toggle',
        btnWrapper: '',
        panelChangeEvent: 'panelchanged',
    };

    constructor() {
        super(...arguments);

        this.$$btnWrapper = this.options.btnWrapper ?
            this.$$element.callAll('closest', this.options.btnWrapper) :
            PanelButton.$$([]);

        ['buttonAction', 'updateState'].forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });

        this.initPanel(this.options.target);

        this.$$element.toggleClass(
            'is-panelbutton-opener',
            this.options.action === 'expand',
        );

        this.$$clickElement =
            this.options.btnWrapperClick && this.$$btnWrapper.length ?
                this.$$btnWrapper :
                this.$$element;

        this.$$clickElement.on('click', (event) => {
            event?.preventDefault();
            this.buttonAction(event);
        });

        /**
         * If the panel button is actually not a button element
         * add the native implicit button events required via WAI-ARIA.
         * @see https://www.w3.org/TR/wai-aria-practices/examples/button/button.html
         */
        this.$$clickElement.on('keyup', (keyEvent) => {
            const { code } = keyEvent;
            if (code === 'Enter' || code === 'Space') {
                keyEvent?.preventDefault();
                this.buttonAction(keyEvent);
            }
        });
    }

    initPanel(target) {
        if (!target) {
            return;
        }

        this.$$panel = PanelButton.$$(target || []);

        this.updateState();
        this.$$panel.on(
            `bronson_${this.options.panelChangeEvent}`,
            this.updateState,
        );
    }

    updateState() {
        const isExpanded = !this.$$panel
            .component('panel')
            .getFirst('isCollapsed');

        this.$$btnWrapper.toggleClass('is-active', isExpanded);

        // Add `[aria-expanded]` only when the action is for toggling actions,
        // not `collapse` (e.g. one-time closing panel).
        if (this.options.action !== 'collapse') {
            this.$$element.attr('aria-expanded', isExpanded);
        }

        // Add `[aria-selected]` when it is a tabbed interface and only when
        // the action is for toggling actions, not `collapse`
        // (e.g. one-time closing panel).
        if (
            this.options.tabbedInterface &&
            this.options.action !== 'collapse'
        ) {
            this.$$element.attr('aria-selected', isExpanded);
        }

        if (this.options.action === 'expand') {
            this.$$element.attr({ tabindex: isExpanded ? -1 : null });
        }
    }

    buttonAction(event) {
        /**
         * Ignore `Enter` key from dispatching a PointerEvent prior to its KeyboardEvent
         * when focussing the action button.
         * @see https://stackoverflow.com/a/57437473
         * @see https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent/pointerId
         */
        if (event?.pointerId === -1) {
            return;
        }
        this.$$panel.component('panel').callAll(this.options.action);
    }

}

PanelButton.register();
