import isPlainObject from './is-plain-object';

/**
 * Deeply merges the content of two or more objects into the first target object - very similar to jQuery's $.extend method with the deep parameter
 * @param target {{}|[]}
 * @param sources {...{}|[]}
 *
 * @return {{}}
 */
export default function extend(target = {}, ...sources) {
    let options;
    let name;
    let src;
    let copy;
    let copyIsArray;
    let clone;

    const { length } = sources;

    if (!target || (typeof target !== 'object' && typeof target !== 'function')) {
        // eslint-disable-next-line no-param-reassign
        target = {};
    }

    for (let i = 0; i < length; i++) {
        options = sources[i];

        if (options != null) {
            /* eslint-disable-next-line guard-for-in */
            for (name in options) {
                src = target[name];
                copy = options[name];

                if (target === copy) {
                    /* eslint-disable-next-line no-continue */
                    continue;
                }

                /* eslint-disable-next-line no-cond-assign */
                if (copy && (isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && Array.isArray(src) ? src : [];
                    } else {
                        clone = src && isPlainObject(src) ? src : {};
                    }

                    target[name] = extend(clone, copy);
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }

    return target;
}
