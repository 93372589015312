/* ------------------------------------*\
    #OVERLAY SCRIPT
\*------------------------------------ */

import Component from '../module/component';

/**
 * The overlay component is a generic UI abstraction for overlaying content.
 * @class Overlay
 * @extends Component
 */
export default class Overlay extends Component {

    static componentName = 'overlay';

    static componentSelector = `.c-overlay`;

    static defaultOptions = Overlay.extendDefaultOptions({
        componentName: Overlay.componentName,
        triggerSelector: '.js-overlay-trigger',
        closeSelector: '.js-overlay-close',
        autofocus: '.js-overlay-autofocus',
        openEventName: 'bronson_overlay_open',
        closeEventName: 'bronson_overlay_close',
    });

    /**
     * Converts a dash-case string into camelCase
     * @param str {string} - The string to convert.
     * @returns {string}
     */
    static convertToCamelCase(str) {
        return str
            .split(/[- _]+/)
            .reverse()
            .reduce((curr, prev) => `${prev}${curr.charAt(0).toUpperCase() + curr.slice(1)}`, '');
    }

    constructor() {
        super(...arguments);
        this.identifier = this.element.getAttribute('id');

        /**
         * Setup triggers per instance to make prototypical inheritance more robust
         * and avoid overiding super-class static properties.
         */
        this.setupTriggers();

        // Trap mouse focus inside of overlay
        this.element.setAttribute('tabindex', -1);

        // Get all closers that are inside the component.
        this.$$closers = this.query(this.options.closeSelector);

        // Register overlay to open event
        this.$$element.on(this.options.openEventName, () => {
            this.open();
        });

        // Register overlay to close event
        this.$$element.on(this.options.closeEventName, () => {
            this.close();
        });

        // Register closers
        this.$$closers.on('click', (event) => {
            event.preventDefault();
            this.triggerClose();
        });

        this.closeOnEsc = this.closeOnEsc.bind(this);
    }

    /**
     * Setup document clicks and scope them to the srespective matching triggers.
     */
    setupTriggers() {
        document.addEventListener('click', (event) => {
            const $trigger = event.target;
            // Excecute only if the clicked element matches is a trigger
            if ($trigger && $trigger.matches(this.options.triggerSelector)) {
                event.preventDefault();
                /**
                 * Dataset properties are camelCased, thats why we need to convert the dash-case compoennt name to it.
                 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLOrForeignElement/dataset#Name_conversion
                 * @type {string}
                 */
                const datasetIndentifier = Overlay.convertToCamelCase(this.options.componentName);
                // Extraxt the target’s element identifier from the trigger dataset.
                const ref = event.target.dataset[datasetIndentifier];

                // Query the target element.
                const target = document.querySelector(`#${ref}`);

                // Emit the {this.options.openEventName} for the target.
                if (target) {
                    // Dispatch a custom element to inform the connected overlay to open
                    target.dispatchEvent(new Event(this.options.openEventName));
                }
            }
        });
    }

    /**
     * Dispatch event to close overlay / trigger close
     */
    triggerClose() {
        this.element.dispatchEvent(new Event(this.options.closeEventName));
    }

    /**
     * Initialise keyboard event listeners.
     */
    initKeyboardTriggers() {
        // Ensure the event gets unbinded.
        window.removeEventListener('keyup', this.closeOnEsc, false);
        window.addEventListener('keyup', this.closeOnEsc, false);
    }

    /**
     * Close Overlay by pressing Escape.
     * Prevent closing, when focus is on input elements
     */
    closeOnEsc({ keyCode, defaultPrevented, target }) {
        if (keyCode === 27 && !defaultPrevented && !target.matches('input, select, textarea, .c-input')) {
            this.triggerClose();
        }
    }

    /**
     * Set the focus inside the overlay.
     */
    setOverlayFocus() {
        const { autofocus } = this.options;

        if (!autofocus) {
            return;
        }

        const autofocusElement = this.element.querySelector(autofocus);

        if (!autofocusElement) {
            return;
        }

        // Currently focused/active element
        this._outsideFocus = document.activeElement;

        if (autofocusElement.tabIndex < 0 && !autofocusElement.getAttribute('tabindex')) {
            autofocusElement.setAttribute('tabindex', -1);
            // Allows to set outline to none
            autofocusElement.classList.add('is-script-focusable');
        }

        try {
            autofocusElement.focus();
        } catch (e) {
            // Continue
        }
    }

    /**
     * Open the overlay.
     */
    open() {
        this.toggle(true);

        this.initKeyboardTriggers();

        this.setOverlayFocus();
    }

    /**
     * Close the overlay.
     */
    close() {
        this.toggle(false);

        // Ensure the event gets unbinded.
        window.removeEventListener('keyup', this.closeOnEsc, false);

        try {
            this._outsideFocus.focus();
        } catch (e) {
            // Continue
        }
        this._outsideFocus = null;
    }

    /**
     * Toggle state of overlay via `aria-hidden`
     * @param {boolean} force true: show, false: hide
     */
    toggle(force) {
        this.$$element.attr('aria-hidden', !force);
    }

}
