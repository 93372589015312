/* ------------------------------------*\
    #RANGE-SLIDER SCRIPT
\*------------------------------------ */

import { createPopper } from '@popperjs/core';
import nouislider from 'nouislider';
import wNumb from 'wnumb';
import Component from '../../../../../src/js/module/component';
import libs from '../../../../../src/js/module/libs';

/**
 * @class CustomRangeSlider
 * @classdesc A custom range slider implementation base on noUiSlider.
 * @extends Component
 * @public
 */
export default class CustomRangeSlider extends Component {

    /**
     * The component name property.
     * @type {string}
     */
    static componentName = 'custom-range-slider';

    /**
     * The component selector property.
     * @type {string}
     */
    static componentSelector = '.js-custom-range-slider';

    /**
     * A reference to the noUiSlider.
     * @type {NoUiSlider}
     */
    static nouislider = nouislider;

    /**
     * The component default options. These can be overridden via `data-*`.
     * @type {string}
     */
    static defaultOptions = {
        connect: [true, false],
        start: 20,
        range: {
            min: 0,
            max: 100,
        },
        format: {
            decimals: 0,
        },
        cssPrefix: 'c-custom-range-slider',
        cssClasses: {
            active: '--active',
            background: '--background',
            draggable: '--draggable',
            horizontal: '--horizontal',
            vertical: '--vertical',
            ltr: '--left-to-right',
            rtl: '--right-to-left',
            target: '__target',
            base: '__base',
            origin: '__origin',
            handle: '__thumb',
            handleLower: '__thumb--lower',
            handleUpper: '__thumb--upper',
            touchArea: '__touch-area',
            connects: '__connect-container',
            connect: '__connect',
            drag: '--drag',
            tap: '--tap',
            tooltip: '__tooltip',
            pips: '__pips',
            pipsHorizontal: '__pips--horizontal',
            pipsVertical: '__pips--vertical',
            marker: '__marker',
            markerHorizontal: '__marker--horizontal',
            markerVertical: '__marker--vertical',
            markerNormal: '__marker--normal',
            markerLarge: '__marker--large',
            markerSub: '__marker--sub',
            value: '__value',
            valueHorizontal: '__value--horizontal',
            valueVertical: '__value--vertical',
            valueNormal: '__value--normal',
            valueLarge: '__value--large',
            valueSub: '__value--sub',
        },
    };

    constructor() {
        super(...arguments);
        if (!(wNumb || nouislider)) {
            console.warn('WARNING: You are using "Custom Range Slider" without its necessary dependencies "nouislider" and "wNumb".');
            return;
        }
        // Process format options with wNumb.js (https://refreshless.com/wnumb/)
        const { format: formatJson } = this.options;
        if (formatJson) {
            this.options.format = wNumb(formatJson);
        }

        /**
         * The noUiSlider instance property.
         * @type {NoUiSlider}
         */
        this.nouislider = nouislider.create(this.element, this.options);

        /**
         * This property holds the Popper instance for mapped to its tooltip element.
         * We chose the WeakMap here because it avoids memory leaks. This can happen when
         * the respective tooltip element is purged from the DOM. In this case the WeakMap
         * removes the key and the value (PopperInstance).
         * @type {WeakMap<HTMLElement, PopperInstance>}
         */
        this.tooltips = new WeakMap();

        const boundary = this.nouislider.target;
        const thumbs = boundary.querySelectorAll(`.${this.options.cssPrefix}${this.options.cssClasses.handle}`);

        const placement = boundary.getAttribute(`data-${this.constructor.componentName}-tooltip-position`) || 'top';

        let tooltips = this.nouislider.getTooltips();

        if (tooltips && tooltips.length) {
            tooltips.forEach((tooltip, i) => {
                const arrow = document.createElement('div');
                arrow.classList.add(`${this.options.cssPrefix}__arrow`);

                /**
                 * A wrapper element has to be created around the tooltip, so that Popper
                 * can control the positioning of the tooltip and the arrow. Popper is rewriting
                 * the DOM inside the wrapper everytime the thumb is moved, so both elements, the
                 * tooltip and the arrow, have to be inside the wrapper to be affected.
                 */
                const popper = document.createElement('div');
                popper.classList.add(`${this.options.cssPrefix}__tooltip-wrapper`);
                tooltip.parentNode.insertBefore(popper, tooltip);
                popper.appendChild(tooltip);
                popper.appendChild(arrow);
                const popperInstance = createPopper(thumbs[i], popper, {
                    placement,
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                boundary,
                                padding: -5, // Make this negative to not exceed the boundary.
                            },
                        },
                        {
                            name: 'offset',
                            options: {
                                /**
                                 * Fine-tuning the position here.
                                 * @see https://popper.js.org/docs/v2/modifiers/offset/
                                 */
                                offset: [0, 15],
                            },
                        },
                        {
                            name: 'arrow',
                            options: {
                                element: arrow,
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                enable: false,
                                enabled: false,
                                flipVariations: false,
                                /**
                                 * This disables the flip behavior.
                                 * @see https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements
                                 */
                                fallbackPlacements: [placement],
                            },
                        },
                    ],
                });

                /**
                 * Store the Popper instance mapped onto the tooltip element it generates.
                 */
                this.tooltips.set(tooltip, popperInstance);

                /**
                 * Listen to the transitionend event on the slider element. This has to be done, because noUiSlider does not
                 * offer an appropriate event {@see https://refreshless.com/nouislider/events-callbacks/}.
                 * When a new value is set and transitions of the handle are settled,
                 * the tooltip´s position gets updated via Popper.
                 */
                this.$$element.on('transitionend', popperInstance.update);

                /**
                 * Listen to the update event on the slider element.
                 * It fires every time the slider values are changed, either
                 * by a user or by calling API methods. Additionally, it fires
                 * immediately when bound.
                 * @see https://refreshless.com/nouislider/events-callbacks/#section-update
                 */
                this.nouislider.on('update', () => {
                    // Check if stored tooltip elements in the DOM have been changed.
                    if (!tooltips.some((t) => this.nouislider.getTooltips().includes(t))) {
                        // Create new WeakMap to restore the Popper instance mapped onto the changed tooltip elements.
                        this.tooltips = new WeakMap();
                        // Get current tooltip elements from the DOM.
                        tooltips = this.nouislider.getTooltips();
                        // Determines all wrapper elements that are controlled by Popper.
                        const tooltipWrapper = boundary.querySelectorAll(`.${this.options.cssPrefix}__tooltip-wrapper`);
                        // Loop changed tooltip elements.
                        tooltips.forEach((updatedTooltip, j) => {
                            // Move tooltip element into the wrapper element.
                            updatedTooltip.parentNode.insertBefore(tooltipWrapper[j], updatedTooltip);
                            tooltipWrapper[j].prepend(updatedTooltip);
                            // Restore the Popper instance mapped onto the tooltip element.
                            this.tooltips.set(updatedTooltip, popperInstance);
                        });
                    }
                    // Update current Popper instance.
                    popperInstance.update();
                });

                /**
                 * Initially update the Popper instance after it has settled. We cannot tap inside noUiSlider events
                 * so we need to synthetically wait for the tooltip to get updated initially.
                 */
                setTimeout(popperInstance.update, 500);
            });
        }

        if ('pips' in this.options && this) {
            // Get the slider's markers and set the thumb at click on the markers
            const markers = this.query(`.${this.options.cssPrefix}${this.options.cssClasses.marker}`);
            markers.on('click', this.setValue);

            // Get the slider's values and set the thumb at click on the values
            const values = this.query(`.${this.options.cssPrefix}${this.options.cssClasses.marker}`);
            values.on('click', this.setValue);

            /**
             * Update markers when values are changed.
             */
            this.nouislider.on('update', () => {
                const currentValues = [this.nouislider.get()].flat(1).map(parseFloat);
                /**
                 * If we have a single handle slider the range is defined via the lowest value
                 * and the current value. Hence we prepend the minimum value to our value array.
                 */
                if (currentValues.length === 1) {
                    currentValues.unshift(this.options.range.min);
                }

                /**
                 * Get the marker value.
                 * @param marker
                 * @return {number}
                 */
                const markerValue = (marker) => parseFloat(marker.nextElementSibling.getAttribute('data-value'));

                /**
                 * Check if a marker exactly matches any of the handle values.
                 * @param marker
                 * @return {boolean}
                 */
                const exactMatch = (marker) => currentValues.some((value) => markerValue(marker) === value);
                /**
                 * Check if a marker value is inside the range of the handle values and not exactly matching one.
                 * @param marker
                 * @return {boolean}
                 */
                const inRange = (marker) => markerValue(marker) >= currentValues[0] &&
                    markerValue(marker) <= currentValues[currentValues.length - 1] &&
                    !exactMatch(marker);

                /**
                 * Assign appropriate marker classes.
                 */
                markers.forEach((marker) => marker.classList.remove('in-range', 'is-active'));
                markers.filter(inRange).forEach((marker) => marker.classList.add('in-range'));
                markers.filter(exactMatch).forEach((marker) => marker.classList.add('is-active'));
            });
        }
    }

    /**
     * This updates the value of a handle and the position of its tooltip if applicable.
     *
     * @param {Object} event
     */
    setValue = ({ target }) => {
        const value = parseFloat(target.hasAttribute('data-value') ? target.getAttribute('data-value') : target.nextElementSibling.getAttribute('data-value'));

        /**
         * Set the value, if the slider is not disabled. So the user can not
         * change the value when the slider is disabled. However, the value can
         * be changed with plain JavaScript.
         */
        if (!this.element.hasAttribute('disabled')) {
            const tooltips = this.nouislider.getTooltips();

            /**
             * Smart noUiSlider return either scalar values or array of values
             * depending on the number of handles. We force single value also in an array
             * to ease the logic down the path. For this we wrap any value into an array
             * and flatten it back down to one dimension.
             * @type {Array<number>}
             */
            const values = [this.nouislider.get()].flat(1).map(parseFloat);

            /**
             * Get the closest value of the collection of values.
             * @type {number}
             */
            const closestValue = values.reduce((a, b) => (Math.abs(b - value) < Math.abs(a - value) ? b : a));

            /**
             * Get the index of the matching handle.
             * @type {number}
             */
            const handleIndex = values.findIndex((v) => v === closestValue);

            /**
             * We need to set correct handle via its position. We pass the handle index,
             * the new value and instruct the slider instance to propagate the `set` event
             * and as well as to show the stepping.
             * We could use `this.nouislider.setHandle(handleIndex, value);` but this
             * prevents the animation when setting handle values →
             * @see https://github.com/leongersen/noUiSlider/issues/1077#issuecomment-698278391
             * @see https://refreshless.com/nouislider/slider-read-write/
             */
            const newHandleValues = values.map((v, i) => (i === handleIndex ? value : null));
            this.nouislider.set(newHandleValues);

            /**
             * Check if we have any tooltips and update only the affected one.
             */
            if (tooltips && tooltips.length) {
                /**
                 * Get the corresponding tooltip to check its boundaries after the update.
                 */
                const tooltip = tooltips[handleIndex];

                /**
                 * Get the affected Popper instance out of the WeakMap
                 * and call its `update` method.
                 */
                this.tooltips.get(tooltip)?.update();
            }
        }
    };

}

CustomRangeSlider.register();

if (nouislider) {
    libs.nouislider = nouislider;
}
