import $$ from './item-collection';
import Component from './component';
import ComponentRegister from './component-register';
import options from './global-options';
import libs from './libs';

export default {
    $$,
    Component,
    ComponentRegister,
    options,
    libs,
};
