import Utils from '../utils';
import throttle from '../module/throttle';
import DimensionChange from '../module/dimension-change';

const stickySupport = Utils.positionStickySupport();

export class Stacked extends DimensionChange {

    static componentName = 'stacked';

    static get fixedStackedClass() {
        return 'is-stacked';
    }

    static componentSelector = '.js-stacked';

    constructor() {
        super(...arguments);

        // Make sure updateStackedPosition isn't called multiple times in one frame
        this.updateStackedPosition = throttle(this.updateStackedPosition, { delay: 0, that: this });

        this.initialTopValue = parseFloat(getComputedStyle(this.element).top) || 0;
        this.stackedHeight = 0;

        Stacked.$$(document).on('bronson_stacked_dimensionchange', this.updateStackedPosition);

        this.$$element.on('bronson_dimensionchange', () => {
            this.trigger('stacked_dimensionchange');
        });

        requestAnimationFrame(() => {
            this.$$element.addClass(Stacked.fixedStackedClass);
            this.trigger('stacked_dimensionchange');
            this.updateStackedPosition();
        });
    }

    updateStackedPosition() {
        // We only support this feature with native sticky support
        if (!stickySupport) {
            return;
        }
        const $$stackedStickies = Stacked.$$(`.${Stacked.fixedStackedClass}`);
        const index = $$stackedStickies
            .findIndex((element) => element === this.element ||
                this.element.compareDocumentPosition(element) !== Node.DOCUMENT_POSITION_PRECEDING);
        const stackedHeight = $$stackedStickies.slice(0, index).getAll('offsetHeight').reduce((start, value) => start + value, 0);

        if (this.stackedHeight !== stackedHeight) {
            this.stackedHeight = stackedHeight;
            requestAnimationFrame(() => {
                this.element.style.top = `${this.initialTopValue + stackedHeight}px`;

                // Chrome bug: Force chrome to render change
                const { position } = this.element.style;

                this.element.style.position = 'static';
                this.element.getBoundingClientRect();
                this.element.style.position = position;
                // Chrome bug: End
            });
        }
    }

}

Stacked.register();

export default class StackedSticky extends DimensionChange {

    static componentName = 'stacked-sticky';

    static componentSelector = '.js-stacked-sticky';

    constructor() {
        super(...arguments);

        this.stacked = this.$$element.component('stacked');
    }

}

StackedSticky.register();
