/* ------------------------------------*\
    #CARDS RESPONSIVE COLLAPSE SCRIPT
\*------------------------------------ */

/**
 * The card body is turning into a dropdown on small screens.
 */

import PanelGroup from '../../../../src/js/components/panelgroup';

class CardResponsiveCollapse extends PanelGroup {

    static componentName = 'card-responsive-collapse';

    static componentSelector = '.js-card-responsive-collapse';

    static defaultOptions = {
        btnSelector: '.js-card-responsive-collapse__toggle',
        panelSelector: '.js-card__body',
        btnAction: 'toggle',
        panelClosedClass: 'is-closed',
        useInert: false,
    };

}

CardResponsiveCollapse.register();

export default CardResponsiveCollapse;
