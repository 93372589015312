/* ------------------------------------*\
    #INLINE-EDITABLE SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';

export default class InlineEditable extends Component {

    static componentName = 'inline-editable';

    static componentSelector = '.js-inline-editable';

    constructor() {
        super(...arguments);
        this.$$element.on('submit', this.onSubmit.bind(this));
        this.$$element.on('reset', this.onReset.bind(this));
        this.submitButton = this.element.querySelector('.js-inline-editable-submit');

        this.editButton = this.element.querySelector('.js-inline-editable-trigger');
        this.editButton.addEventListener('click', this.onClick.bind(this));

        this.input = this.element.querySelector('.js-inline-editable-input');
        this.addon = this.element.querySelector('.c-input__addon');

        this.input.addEventListener('click', this.onClick.bind(this));
        this.input.addEventListener('input', this.onChange.bind(this));
        this.initialValue = this.input.value;
        this.setInputSize(this.input.value);
    }

    /**
     * Handle clicks on the element itself and add the state class.
     */
    onClick() {
        if (!(this.element.getAttribute('aria-hidden') === 'false')) {
            this.element.setAttribute('aria-hidden', 'false');
            this.$$element.off('click', this.onClick);
            this.input.focus();
        }
    }

    /**
     * Handle value changes.
     * Remove the disabled state if the initial
     * value differs from the input.
     */
    onChange() {
        this.setInputSize(this.input.value);
        if (this.initialValue === this.input.value) {
            this.submitButton.setAttribute('disabled', true);
        } else {
            this.submitButton.removeAttribute('disabled');
        }
    }

    /**
     * Handle the form submit.
     */
    onSubmit(event) {
        event.preventDefault();
        this.element.setAttribute('aria-hidden', true);
        this.initialValue = this.input.value;
        this.input.defaultValue = this.initialValue;
        this.submitButton.setAttribute('disabled', true);
        this.input.blur();
    }

    /**
     * Handle the form reset.
     */
    onReset() {
        this.setInputSize(this.initialValue);
        this.element.setAttribute('aria-hidden', true);
        this.input.blur();
    }

    /**
     * Set the input value to the the sizer element.
     * @param {string} value
     */
    setInputSize(value) {
        /**
         * Replace regular spaces with non-breaking spaces here as browsers
         * normalize the `attr()` strings by removing leading/trailing spaces.
         * @see https://developer.mozilla.org/en-US/docs/Web/CSS/attr()
         */
        const str = value.replaceAll(' ', ' ');
        this.input.parentElement.setAttribute('data-inline-editable-size', str);
    }

}

InlineEditable.register();
