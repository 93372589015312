// Adds workaround for equal height cells: See https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144

import Flickity from 'flickity';
/**
 * Has to be imported right after Flickity.
 * @see https://flickity.metafizzy.co/options.html#fullscreen
 */
import 'flickity-fullscreen';

if (Flickity) {
    const resizeFn = Flickity.prototype.resize;

    Object.assign(Flickity.prototype, {
        _createResizeClass() {
            this._toggleResizeClass(true);
        },

        _toggleResizeClass(force, instant) {
            const { setGallerySize, resize } = this.options;

            if (setGallerySize && resize) {
                const fn = () => {
                    this.element.classList.toggle('flickity-resize', force);
                };

                if (instant) {
                    fn();
                } else {
                    requestAnimationFrame(fn);
                }
            }
        },

        resize() {
            this._toggleResizeClass(false, true);

            resizeFn.call(this);

            this._toggleResizeClass(true);
        },
    });

    Flickity.createMethods.push('_createResizeClass');
}

export default Flickity;
