/* ------------------------------------*\
    #TOAST NOTIFICATION SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

export default class ToastNotifications extends PanelGroup {

    static componentName = 'toast-notification';

    static componentSelector = '.js-toast-notifications';

    static defaultOptions = {
        btnSelector: '.c-toast-notification__close',
        panelSelector: '.c-toast-notification',
        multiple: true,
        btnAction: 'collapse',
        defaultCollapsed: false,
        animateHeightClass: 'is-closed',
    };

}

ToastNotifications.register();
