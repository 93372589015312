/* ------------------------------------*\
    #TABLE ARIA ROLES SCRIPT
\*------------------------------------ */

import Component from './component';

/**
 * Add ARIA `role` attributes to all standard table elements of responsive
 * tables to restore table semantics if these elements have a different
 * `display` value.
 *
 * The `addTableARIA` function is based on https://codepen.io/gunnarbittersmann/pen/qvLGQz,
 * which is based on https://adrianroselli.com/2018/05/functions-to-add-aria-to-tables-and-lists.html.
 *
 * Original markup:
 *
 *    <div class="js-table-responsive">
 *     <table>
 *       <thead>
 *         <tr>
 *           <th>...</th>
 *           <th>...</th>
 *         </tr>
 *       </thead>
 *       <tbody>
 *         <tr>
 *           <th scope="row">...</th>
 *           <td>...</td>
 *         </tr>
 *       </tbody>
 *     </table>
 *   </div>
 *
 * Modified markup:
 *
 *   <div class="js-table-responsive">
 *     <table role="table">
 *       <thead role="rowgroup">
 *         <tr role="row">
 *           <th role="columnheader">...</th>
 *           <th role="columnheader">...</th>
 *         </tr>
 *       </thead>
 *       <tbody role="rowgroup">
 *         <tr role="row">
 *           <th scope="row" role="rowheader">...</th>
 *           <td role="cell">...</td>
 *         </tr>
 *       </tbody>
 *     </table>
 *  </div>
 *
 * @class TableAriaRoles
 * @extends Component
 */

export default class TableAriaRoles extends Component {

    static componentName = 'tables-aria';

    static componentSelector = '.js-table-responsive > table';

    addTableARIA() {
        try {
            const table = this.element;

            table.setAttribute('role', 'table');

            const allRowGroups = table.querySelectorAll('thead, tbody, tfoot');
            for (let j = 0; j < allRowGroups.length; j++) {
                allRowGroups[j].setAttribute('role', 'rowgroup');

                const allRows = allRowGroups[j].querySelectorAll('tr');
                for (let k = 0; k < allRows.length; k++) {
                    allRows[k].setAttribute('role', 'row');

                    const allCells = allRows[k].querySelectorAll('td');
                    for (let l = 0; l < allCells.length; l++) {
                        allCells[l].setAttribute('role', 'cell');
                    }
                    const allHeaders = allRows[k].querySelectorAll('th');
                    for (let l = 0; l < allHeaders.length; l++) {
                        if (!(allHeaders[l].matches('th[scope=row]'))) {
                            allHeaders[l].setAttribute('role', 'columnheader');
                        } else {
                            // this accounts for scoped row headers
                            allHeaders[l].setAttribute('role', 'rowheader');
                        }
                    }
                    // caption role not needed as it is not a real role and
                    // browsers do not dump their own role with display block
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`addTableARIA(): ${e}`);
        }
    }

    constructor() {
        super(...arguments);
        this.addTableARIA();
    }

}

TableAriaRoles.register();
