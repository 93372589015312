import { createPopper } from '@popperjs/core';
import BronsonComponent from '../../../../../src/js/module/component';

export default class DropdownContainer extends BronsonComponent {

    static componentName = 'dropdown-container';

    static componentSelector = '.js-dropdown-container';

    static defaultOptions = BronsonComponent.extendDefaultOptions({
        dropdownMenuTriggerSelector: '.js-dropdown-container__trigger-wrapper',
        dropdownMenuContainerSelector: '.js-dropdown-container__panel',
        dropdownMenuArrowSelector: '.js-dropdown-container__arrow',
        direction: 'bottom',
        flip: true,
        open: false,
        showArrow: true,
    });

    static focusableSelectors =
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

    constructor() {
        super(...arguments);

        const {
            dropdownMenuTriggerSelector,
            dropdownMenuContainerSelector,
            flip,
            direction,
            open,
            showArrow,
        } = this.options;

        /**
         * Query essential elements.
         */
        this.triggerWrapper = this.element.querySelector(dropdownMenuTriggerSelector);
        this.trigger = this.triggerWrapper?.querySelector('button');
        this.container = this.element.querySelector(dropdownMenuContainerSelector);
        this.containerOffset = getComputedStyle(this.element).getPropertyValue('--js-dropdown-container-offset') ?? 0;

        /**
         * If any of the non-essential elements are not present we throw an error.
         */
        if (!(this.triggerWrapper || this.trigger || this.container)) {
            // eslint-disable-next-line no-console
            console.error('Bronson dropdown needs a valid trigger and container,');
        }

        /**
         * Set initial component state.
         */
        this.isVisible = open;

        /**
         * Conditionally add arrow.
         */
        if (showArrow) {
            /**
             * Construct arrow and append it as first item.
             */
            this.arrow = document.createElement('span');
            this.arrow?.classList.add('c-dropdown-container__arrow', 'js-dropdown-container__arrow');
            this.arrow?.setAttribute('aria-hidden', 'true');
            this.container.prepend(this.arrow);
        }

        this.container?.setAttribute('aria-hidden', !open);
        this.container?.addEventListener('keydown', this.#handleFocusTrap.bind(this), { capture: true });

        this.trigger?.setAttribute('aria-controls', this.container?.getAttribute('id'));
        this.trigger?.setAttribute('aria-expanded', open);

        /**
         * Assign event handlers.
         */
        this.trigger?.addEventListener('click', this.onClick.bind(this));
        document.addEventListener('click', this.#outsideClick.bind(this), {
            capture: true,
        });
        document.addEventListener('keydown', this.#handleEscape.bind(this), {
            capture: true,
        });

        /**
         * Configure PopperJS to handle placement and arrow tracking.
         */
        this.popper = createPopper(this.trigger, this.container, {
            placement: `${direction}-start`,
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: document.body,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        /**
                         * Fine-tuning the position here.
                         * @see https://popper.js.org/docs/v2/modifiers/offset/
                         */
                        offset: [0, parseInt(this.containerOffset, 10)],
                    },
                },
                ...showArrow ? [{
                    name: 'arrow',
                    options: {
                        element: this.arrow,
                    },
                }] : [],
                {
                    name: 'flip',
                    options: {
                        /**
                         * This disables the flip behavior.
                         * @see https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements
                         */
                        ...(!flip && { fallbackPlacements: [direction] }),
                    },
                },
            ],
        });
    }

    /**
     * Close the DropdownContainer when 'Esc' key was pressed.
     * @param {KeyboardEvent} event
     * @private
     */
    #handleEscape(event) {
        if (event.key === 'Escape') {
            const firstFocusable = this.triggerWrapper?.querySelector(DropdownContainer.focusableSelectors);
            this.close();
            firstFocusable?.focus();
        }
    }

    /**
     * Naive focusTrap implementation.
     * @see https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
     * @param {KeyboardEvent} event
     * @private
     */
    #handleFocusTrap(event) {
        const { key, shiftKey } = event;
        const focusableElements = Array.from(this.container?.querySelectorAll(DropdownContainer.focusableSelectors));
        const firstFocusableElement = focusableElements?.at(0);
        const lastFocusableElement = focusableElements?.at(-1);

        /**
         * If the pressed key is not a 'Tab' bail out.
         */
        if (key !== 'Tab') {
            return;
        }

        /**
         * Handle focus on the item bounds when the 'Shift'
         */
        if (shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                event.preventDefault();
            }
        } else if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            event.preventDefault();
        }
    }

    /**
     * Close the DropdownContainer when the menu itself is visible
     * and a click occurred outside its container
     * and is not the {@link this.trigger} element nor any of its children.
     * @param {MouseEvent} event
     * @private
     */
    #outsideClick(event) {
        if (
            this.isVisible &&
            !event?.target.closest('.c-dropdown-container__panel') &&
            !this.trigger?.contains(event?.target)
        ) {
            this.close();
        }
    }

    /**
     * Set visibility attributes.
     * @private
     */
    #setA11yAttributes() {
        this.container?.setAttribute('aria-hidden', !this.isVisible);
        this.trigger?.setAttribute('aria-expanded', this.isVisible);
    }

    /**
     * Close the DropdownContainer container.
     */
    close() {
        this.isVisible = false;
        this.#setA11yAttributes();
    }

    /**
     * Show the DropdownContainer container.
     */
    show() {
        this.isVisible = true;
        this.#setA11yAttributes();
    }

    /**
     * Toggle the DropdownContainer container.
     */
    toggle() {
        this.isVisible = !this.isVisible;
        this.#setA11yAttributes();
    }

    /**
     * Handle the {@link this.trigger} interactions.
     */
    onClick() {
        this.toggle();
    }

}

DropdownContainer.register();
