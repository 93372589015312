/* ------------------------------------*\
    #TOOLTIP SCRIPT
\*------------------------------------ */

import tippy from 'tippy.js';
import Component from '../../../../../src/js/module/component';

/**
 * Hide tooltips when they and their children lose focus.
 * @see https://atomiks.github.io/tippyjs/v6/plugins/#hideonpopperblur
 * @type {{defaultValue: boolean, name: string, fn(*=): {onCreate(): void}}}
 */
export const hideOnPopperBlur = {
    name: 'hideOnPopperBlur',
    defaultValue: true,
    fn(instance) {
        return {
            onCreate() {
                instance.popper.addEventListener('focusout', (event) => {
                    if (
                        instance.props.hideOnPopperBlur &&
                        event.relatedTarget &&
                        !instance.popper.contains(event.relatedTarget)
                    ) {
                        instance.hide();
                    }
                });
                instance.popper.addEventListener('click', (event) => event.stopPropagation());
                instance.popper.addEventListener('keyup', (event) => event.stopPropagation());
            },
        };
    },
};

export default class Tooltip extends Component {

    static componentSelector = '.js-tooltip';

    static componentName = 'tooltip';

    static tippy = tippy;

    static errors = {
        missingContent: `WARNING: Tooltip is missing content, either via '[data-tooltip-content]' or '[data-template]'. The tooltip will not be initialized.`,
        missingTemplateElement: `Oh shoot! Seems like the corresponding template to a tooltip was not found. Make sure to link all tooltip triggers ('.js-tooltip') that contain a '[data-template]' attribute to its corresponding templates by matching the '[data-template]' value of '.js-tooltip' with the '[id]' attribute of the template.'`,
        missingDependency: 'WARNING: You are using "Tooltip" without its necessary dependency "tippy.js".',
    };

    /**
     * [1] Does not work in combination with `appendTo`.
     *     https://atomiks.github.io/tippyjs/v6/all-props/#appendto
     *     https://atomiks.github.io/tippyjs/v6/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all
     * [2] Please always try to position the tooltip in a clipping context to never
     *     hide tooltip content. Comes with the downside that tooltips sometimes are not centered etc..
     *     https://atomiks.github.io/tippyjs/v6/all-props/#popperoptions
     *     https://popper.js.org/docs/v2/modifiers/prevent-overflow/
     * [3] Link the tooltip trigger and the tooltip template.
     */
    static defaultOptions = {
        arrow: true,
        hideOnClick: false,
        interactive: true, // [1]
        placement: 'bottom',
        theme: 'default',
        animation: 'default',
        allowHTML: true,
        popperOptions: { // [2]
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['bottom', 'right'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        tether: false,
                    },
                },
            ],
        },
        content(reference) { // [3]
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);

            // Safeguard if tooltip uses content via `[data-template]`.
            if (id) {
                // Just link the trigger and the template if there
                // is a template.
                if (template) {
                    return template.innerHTML;
                }
                // eslint-disable-next-line no-console
                console.error(Tooltip.errors.missingTemplateElement);
            }

            // Do nothing, use content via `[data-tippy-content]`.
            return null;
        },
        /**
         * Set the z-index of the parent element high enough and disable any potential
         * transitioning of it. The z-index value 2001 is inherited from the header setting in
         * {@see packages/bronson-default/src/scss/01-settings/_settings.z-layers.scss}
         * @param {object} instance - A tippy instance is an individual tippy object.
         */
        onTrigger(instance) {
            instance.reference.parentElement.style.setProperty('transition-duration', '0s');
            instance.reference.parentElement.style.setProperty('z-index', 2001);
        },
        /**
         * Reset the z-index and the transition-duration from the {onTrigger} method.
         * @param {object} instance - A tippy instance is an individual tippy object.
         */
        onHidden(instance) {
            instance.reference.parentElement.style.removeProperty('z-index');
            instance.reference.parentElement.style.removeProperty('transition-duration');
        },
        plugins: [
            hideOnPopperBlur,
        ],
    };

    static init() {
        const tooltipElement = document.querySelector(this.componentSelector);

        if (tooltipElement) {
            const { missingContent, missingDependency } = Tooltip.errors;

            // Check if tippy.js is available
            if (tippy) {
                // Set default options if someone wants to use the `data-tippy` attribute.
                tippy.setDefaultProps(this.defaultOptions);

                const { tippyContent, template } = tooltipElement.dataset;

                // Only initialize tooltip when content is set
                if (tippyContent || template) {
                    tippy(this.componentSelector, this.defaultOptions);
                } else {
                    // eslint-disable-next-line no-console
                    console.error(missingContent);
                }
            } else {
                // eslint-disable-next-line no-console
                console.warn(missingDependency);
            }
        }
    }

}

Tooltip.register();

if (tippy) {
    window.tippy = tippy;
}
