/* ------------------------------------*\
    #SITE NAV SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

class SiteNav extends PanelGroup {

    static componentName = 'site-nav';

    static componentSelector = '.js-site-nav';

    static defaultOptions = {
        btnSelector: '.js-site-nav__toggle',
        panelSelector: '.js-site-nav__navs',
        btnWrapper: SiteNav.componentSelector,
        defaultCollapsed: false,
        panelClosedClass: 'is-collapsed',
        useInert: false,
    };

}

SiteNav.register();

export default SiteNav;
