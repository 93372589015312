import $$ from './item-collection';

const elementExpando = `_bronson_transitionAuto${Date.now()}`;
const transitionSupport = 'transition' in document.createElement('i').style;

function cleanElement(element) {
    if (!element[elementExpando]) {
        return;
    }

    if (element[elementExpando].endEvent) {
        element.removeEventListener('transitionend', element[elementExpando].endEvent);
    }

    if (element[elementExpando].timer) {
        cancelAnimationFrame(element[elementExpando].timer);
    }

    element[elementExpando] = null;
}

function setToggleObj(element, toggleDefinition) {
    if (toggleDefinition) {
        if (toggleDefinition.className) {
            element.classList.toggle(toggleDefinition.className, toggleDefinition.force);
        } else if (toggleDefinition.attr) {
            if (toggleDefinition.value !== null) {
                element.setAttribute(toggleDefinition.attr, toggleDefinition.value);
            } else {
                element.removeAttribute(toggleDefinition.attr);
            }
        } else if (typeof toggleDefinition === 'function') {
            toggleDefinition(element);
        }
    }
}

function transitionToValue(element, value, toggleDefinition, property = 'height', onStart = () => {}, onEnd = () => {}) {
    if (transitionSupport) {
        const currentValue = element[property === 'width' ? 'offsetWidth' : 'offsetHeight'];
        const noFx = toggleDefinition && toggleDefinition.noFx;
        const overflowType = property === 'width' ?
            'overflowX' :
            'overflowY';
        const isHidden = !toggleDefinition.noHide && (!value || value === '0px');
        const setEndStyle = () => {
            element.style.transition = '';
            element.style[property] = value;
            element.style.visibility = isHidden ? 'hidden' : '';
        };

        if (noFx) {
            element.style.transition = 'none';
        }

        element[elementExpando] = {
            endEvent(e) {
                if (e.propertyName === property && e.target === element) {
                    cleanElement(element);
                    onEnd(element);
                }
            },
            timer: requestAnimationFrame(() => {
                if (noFx) {
                    element[elementExpando].timer = requestAnimationFrame(() => {
                        cleanElement(element);
                        onEnd(element);

                        setEndStyle();
                        setToggleObj(element, toggleDefinition);

                        element.style[overflowType] = 'hidden';
                    });
                } else {
                    element.style.transition = 'none';
                    element.style[property] = `${currentValue}px`;

                    element[elementExpando].timer = requestAnimationFrame(() => {
                        onStart(element);

                        element.style[overflowType] = 'hidden';

                        setEndStyle();

                        element.addEventListener('transitionend', element[elementExpando].endEvent);

                        setToggleObj(element, toggleDefinition);
                    });
                }
            }),
        };
    } else {
        requestAnimationFrame(() => {
            setToggleObj(element, toggleDefinition);
        });
    }
}

function transitionToAuto(element, toggleDefinition, property = 'height', onStart = () => {}, onEnd = () => {}) {
    if (transitionSupport) {
        const noFx = toggleDefinition && toggleDefinition.noFx;
        const overflowType = property === 'width' ?
            'overflowX' :
            'overflowY';

        if (noFx) {
            element.style.transition = 'none';
        }

        element[elementExpando] = {
            endEvent(e) {
                if (e.propertyName === property && e.target === element) {
                    cleanElement(element);
                    onEnd(element);

                    element.style[property] = '';
                    element.style[overflowType] = '';
                }
            },
            timer: requestAnimationFrame(() => {
                setToggleObj(element, toggleDefinition);

                if (noFx) {
                    element[elementExpando].timer = requestAnimationFrame(() => {
                        cleanElement(element);
                        onEnd(element);

                        element.style.transition = '';
                        element.style.visibility = '';
                        element.style[property] = '';
                        element.style[overflowType] = '';
                    });
                } else {
                    onStart(element);

                    const endValue = element[property === 'width' ? 'scrollWidth' : 'scrollHeight'];

                    element.style[property] = `${endValue}px`;
                    element.style[overflowType] = 'hidden';
                    element.style.visibility = '';

                    element.addEventListener('transitionend', element[elementExpando].endEvent);
                }
            }),
        };
    } else {
        setToggleObj(element, toggleDefinition);
    }
}

function transitionWidthHeightAuto(element, toggleDefinition, value = '0px', property = 'height', onStart = () => {
}, onEnd = () => {
}) {
    cleanElement(element);

    if (value === 'auto') {
        transitionToAuto(element, toggleDefinition, property, onStart, onEnd);
    } else {
        transitionToValue(element, value, toggleDefinition, property, onStart, onEnd);
    }
}

/**
 * Allows height CSS transition from or towards the 'auto' value. The container should have no padding, margin, border. Most of the animation should be done via CSS (especially 'visibility').
 * @param {String, 0} value either string or 0
 * @param {Object} toggleDefinition
 * @param {String} toggleDefinition.className The class that should be added/removed
 * @param {Boolean, null} [toggleDefinition.force] Whether the class should be added (true), removed (false) or toggled (null/undefined)
 * @returns {$$Collection}
 *
 * @example
 *
 * $$('.foo').toggleHeightTransition(0, {className: 'is-closed', force: true});
 *
 * $$('.foo').toggleHeightTransition('auto', {className: 'is-closed', force: false});
 */
$$.fn.toggleHeightTransition = function toggleHeightTransition(
    value,
    toggleDefinition,
    onStart = () => {},
    onEnd = () => {},
) {
    return this.forEach((element) => {
        transitionWidthHeightAuto(element, toggleDefinition, value, 'height', onStart, onEnd);
    });
};

/**
 * Toggles the given hiddenClass and performs an animation to 0 (if hiddenClass is added) or 'auto' (if hiddenClass is removed)
 * @param hiddenClass {''}
 * @param [noFx] {boolean}
 * @returns {$$Collection}
 */
$$.fn.toggleAutoHeightTransition = function toggleAutoHeightTransition(hiddenClass, noFx) {
    return this.forEach((element) => {
        const isHidden = element.classList.contains(hiddenClass);

        transitionWidthHeightAuto(element, {
            className: hiddenClass,
            force: !isHidden,
            noFx,
        }, isHidden ? 'auto' : 0, 'height');
    });
};

/**
 * Allows width CSS transition from or towards the 'auto' value. The container should have no padding, margin, border. Most of the animation should be done via CSS (especially 'visibility').
 * @param {String, 0} value either string or 0
 * @param {Object} toggleDefinition
 * @param {String} toggleDefinition.className The class that should be added/removed
 * @param {Boolean, null} [toggleDefinition.force] Whether the class should be added (true), removed (false) or toggled (null/undefined)
 * @returns {$$Collection}
 */
$$.fn.toggleWidthTransition = function toggleWidthTransition(value, toggleDefinition) {
    return this.forEach((element) => {
        transitionWidthHeightAuto(element, toggleDefinition, value, 'width');
    });
};

/**
 * Toggles the given hiddenClass and performs an animation to 0 (if hiddenClass is added) or 'auto' (if hiddenClass is removed)
 * @param hiddenClass {''}
 * @param [noFx] {boolean}
 * @returns {$$Collection}
 */
$$.fn.toggleAutoWidthTransition = function toggleAutoWidthTransition(hiddenClass, noFx) {
    return this.forEach((element) => {
        const isHidden = element.classList.contains(hiddenClass);

        transitionWidthHeightAuto(element, {
            className: hiddenClass,
            force: !isHidden,
            noFx,
        }, isHidden ? 'auto' : 0, 'width');
    });
};
