/* ------------------------------------*\
    #DROP DOWN SCRIPT
\*------------------------------------ */

import PanelGroup from './panelgroup';

export default class DropDown extends PanelGroup {

    static componentName = 'dropdown';

    static componentSelector = '.js-dropdown';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.js-dropdown__button',
        panelSelector: '.js-dropdown__panel',
        checkNesting: true,
        panelClosedClass: 'is-collapsed',
    });

    constructor() {
        super(...arguments);

        ['_panelChanged', '_outsideAction', '_onEsc'].forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });

        this.$$panels.on('bronson_panelchanged', this._panelChanged);

        this.$$element.on('keydown', this._onEsc);
    }

    _onEsc({ keyCode, defaultPrevented, target }) {
        if (keyCode === 27 && !defaultPrevented && !target.matches('input, select, textarea')) {
            this.collapseAll();
        }
    }

    _outsideAction({ target }) {
        if (!this.element.contains(target)) {
            this.collapseAll();
        }
    }

    _panelChanged() {
        // add or remove closing events on panel change
        if (this.$$panels.component('panel').getFirst('isCollapsed')) {
            //@Todo: discuss should we use event capturing?
            // Use 'mouseup' instead of 'mousedown' to avoid empty targets.
            DropDown.$$(document)
                .off('mouseup', this._outsideAction)
                .off('click', this._outsideAction)
                .off('focus', this._outsideAction);
        } else {
            DropDown.$$(document)
                .on('mouseup', this._outsideAction)
                .on('click', this._outsideAction)
                .on('focus', this._outsideAction);
        }
    }

}

DropDown.register();
