/* ------------------------------------*\
    #CUSTOM-PROGRESS
\*------------------------------------ */

import tippy from 'tippy.js';
import CustomRangeSlider from '../51-custom-range-slider/custom-range-slider';

export default class CustomProgress extends CustomRangeSlider {

    static componentName = 'custom-progress';

    static componentSelector = '.js-custom-progress';

    static defaultOptions = CustomRangeSlider.extendDefaultOptions({
        cssPrefix: 'c-custom-progress',
        keyboardSupport: false,
    });

    constructor() {
        super(...arguments);

        this.initTooltips();
        this.addIcons();
    }

    initTooltips() {
        const tooltipTemplates = Array.from(this.element?.querySelectorAll('[data-custom-progress-tooltip]')) || [];
        const referenceElements = Array.from(this.element?.querySelectorAll('.c-custom-progress__thumb'));

        // Loop over all tooltips
        tooltipTemplates.forEach((tooltipTemplate, index) => {
            // For multiple tooltips, use connectors as trigger target
            // null = parent (default)
            const triggerTarget = this.element?.querySelectorAll('.c-custom-progress__connect, .c-custom-progress__thumb')[index] || null;
            const hover = (tooltipTemplate.getAttribute('data-custom-progress-tooltip-hover') === 'true');
            const placement = tooltipTemplate.getAttribute('data-custom-progress-tooltip-placement');

            tippy(referenceElements[index], {
                trigger: hover ? 'mouseenter' : 'manual',
                triggerTarget,
                showOnCreate: !hover,
                hideOnClick: false,
                allowHTML: true,
                appendTo: this.element,
                maxWidth: 'none',
                onCreate(instance) {
                    // Add a modifier class if the tooltip is placed at the bottom for styling.
                    if (placement === 'bottom') {
                        triggerTarget.classList.add(`c-custom-progress__tooltip-placement--${placement}`);
                        instance.reference.classList.add(`c-custom-progress__tooltip-placement--${placement}`);
                    }

                    // Check if the step has an initial tooltip and add the `*--main` modifier class for styling.
                    if (!hover) {
                        triggerTarget.classList.add(`c-custom-progress__step--main`);
                        instance.reference.classList.add(`c-custom-progress__step--main`);
                    }
                },
                content() {
                    return tooltipTemplate.innerHTML;
                },
                placement: placement || 'top',
                popperOptions: {
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                enable: false,
                                enabled: false,
                                flipVariations: false,
                                /**
                                 * This disables the flip behavior.
                                 * @see https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements
                                 */
                                fallbackPlacements: [],
                            },
                        },
                    ],
                },
            });
        });
    }

    addIcons() {
        const icons = this.element.getAttribute('data-custom-progress-icons')?.split(',') ?? [];

        // Only add icons if there are any
        if (icons.length) {
            // Loop through each `.c-custom-progress__origin` element and add an icon to it
            this.element.querySelectorAll('.c-custom-progress__origin').forEach((origin, index) => {
                const icon = icons[index];

                // If there’s no icon for this index, return early
                if (!icon) { return; }

                // Add the icon to the '.c-custom-progress__thumb' element
                const thumb = origin.querySelector('.c-custom-progress__thumb');
                thumb.insertAdjacentHTML('beforeend', `<i class="c-custom-progress__thumb__icon  c-icon  c-icon--[${icon}]" aria-hidden="true" role="img"></i>`);

                /**
                 * Add fallback class when `:has()` is not supported.
                 * @see https://www.bram.us/2023/01/04/css-has-feature-detection-with-supportsselector-you-want-has-not-has/
                 * @DEPRECATED: Remove when browser support is sufficient.
                 */
                if (!CSS.supports('selector(:has(+ *))')) {
                    thumb.classList.add('c-custom-progress__thumb--has-icon');
                }
            });
        }
    }

}

CustomProgress.register();
