import Component from './component';
import throttle from './throttle';

const layoutChangeProperties = [
    'width',
    'height',
    'border-top-width',
    'border-right-width',
    'border-bottom-width',
    'border-left-width',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',
    'flex-grow',
    'flex-basis',
];

export default class DimensionChange extends Component {

    static componentName = 'dimension-change';

    static componentSelector = '';

    static layoutChangeMap = layoutChangeProperties.reduce((obj, prop) => {
        obj[prop] = true;

        return obj;
    }, {});

    constructor() {
        super(...arguments);

        this._bindDimensionChangeEvents();
    }

    _bindDimensionChangeEvents() {
        if (this._hasEventsBound) {
            return;
        }

        this._hasEventsBound = true;

        this.testDimensionChange = throttle(this.testDimensionChange, { that: this });

        this.$$element
            .on('transitionend', ({ propertyName }) => {
                if (DimensionChange.layoutChangeMap[propertyName]) {
                    this.testDimensionChange();
                }
            })
            .on('load', this.testDimensionChange, true);

        DimensionChange.$$(window).on('resize', this.testDimensionChange);
    }

    testDimensionChange() {
        const { offsetHeight, offsetWidth } = this.element;

        if (offsetHeight !== this.offsetHeight || offsetWidth !== this.offsetWidth) {
            this.offsetHeight = offsetHeight;
            this.offsetWidth = offsetWidth;
            this.trigger('dimensionchange');
        }
    }

}
