const console = window.console || {};
const logger = {};

['log', 'info', 'warn', 'error'].forEach((name) => {
    if (!console[name]) {
        logger[name] = function () {
            return console.log && console.log(...arguments);
        };
    } else if (!console[name].bind) {
        logger[name] = function () {
            return console[name](...arguments);
        };
    } else {
        logger[name] = console[name].bind(console);
    }
});

export default logger;
