/* ------------------------------------*\
    #FOOTER-ACCORDION SCRIPT
\*------------------------------------ */

/**
 * In order to save some space on small screen, the nav in the footer is turning
 * into an accordion.
 */

import Component from '../../../../../src/js/module/component';

// The function `this.transformMarkup()` needs to finish before the PanelGroup
// is initialized in the `constructor()`. Therefore it is easier to call it with
// `this.$$element.component('panelgroup', this.options.panelgroupOptions);`
// than to extend it.
export default class FooterAccordion extends Component {

    static componentName = 'footer-accordion';

    static componentSelector = '.js-footer-accordion';

    static defaultOptions = {
        panelgroupOptions: {
            btnSelector: '.js-footer-accordion__title-label',
            panelSelector: '.js-footer-accordion__panel',
            btnWrapper: '.js-footer-accordion__title',
            btnWrapperClick: true,
            btnAction: 'toggle',
            panelClosedClass: 'is-closed',
            useInert: false,
        },
    };

    constructor() {
        super(...arguments);

        this.transformMarkup();

        this.$$element.component('panelgroup', this.options.panelgroupOptions);
    }

    transformMarkup() {
        const { btnSelector } = this.options.panelgroupOptions;

        this.$$element.query(btnSelector).forEach((element) => {
            const { className, innerHTML } = element;
            const ariaExpanded = element.getAttribute('aria-expanded');
            const button = document.createElement('button');

            element.classList.remove(btnSelector.replace('.', ''));
            element.classList.add('is-heading');
            element.removeAttribute('aria-expanded');

            Object.assign(button, {
                className,
                innerHTML,
                type: 'button',
            });

            button.classList.add('is-button');

            if (ariaExpanded) {
                button.setAttribute('aria-expanded', ariaExpanded);
            }

            element.parentNode.append(button);
        });
    }

}

FooterAccordion.register();
