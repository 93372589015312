/* ------------------------------------*\
    #STICKY-CONTAINER SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';

export default class StickyContainer extends Component {

    static componentName = 'sticky-container';

    static componentSelector = '.js-sticky-container';

    static stickyTimeout = 100;

    static defaultOptions = {
        isStickyClass: 'is-sticky',
        isTransitioningClass: 'is-sticky-transitioning',
    };

    constructor() {
        super(...arguments);

        this.$$element.component('stacked-sticky');

        this.initialHeight = this.element.clientHeight;

        this.isSticky = false;

        this.element.addEventListener('transitionend', () => {
            this.element.classList.remove(this.options.isTransitioningClass);
        });

        this.element.addEventListener('transitionstart', () => {
            this.element.classList.add(this.options.isTransitioningClass);
        });

        // Setup scroll listener to check whether the element is sticky.
        window.addEventListener('scroll', () => requestAnimationFrame(this.checkIfElementIsSticky.bind(this)));
    }

    /**
     * Compares the element’s scroll top/bottom offset against its computed top/bottom value.
     */
    checkIfElementIsSticky() {
        /**
         * If we are tranistioning we skip checking for stickyness.
         */
        if (this.element.classList.contains(this.options.isTransitioningClass)) {
            return false;
        }

        /**
         * Checks which positioning mode the position: sticky adheres to.
         * @param bottom {string} - The CSS bottom of the element.
         * @param top {string} - The CSS top of the element.
         * @returns {boolean} - Returns a boolean indicating if the element is in sticky position.
         */
        const isSticky = (bottom, top) => {
            const { top: offsetTop } = this.element.getBoundingClientRect();
            if (bottom === 'auto' && top.search('px')) {
                return offsetTop <= parseFloat(top, 10);
            }
            return false;
        };

        const { bottom: cssBottom, top: cssTop } = window.getComputedStyle(this.element);
        const sticks = isSticky(cssBottom, cssTop);

        if (sticks) {
            this.element.classList.add(this.options.isStickyClass);
        } else {
            this.element.classList.remove(this.options.isStickyClass);
        }
    }

}

StickyContainer.register();
