/* ------------------------------------*\
    #ACTION-BUTTONS-CONTAINER SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';
import debounce from '../../../../../src/js/module/debounce';

export class ActionButtonContainer extends Component {

    static componentName = 'action-buttons-container-toggle';

    static componentSelector = '.js-action-buttons-container-toggle';

    static defaultOptions = {
        scrollOffset: 200,
    };

    constructor() {
        super(...arguments);

        const { scrollOffset } = this.options;

        //  Debounce the scroll event that toggles a `.is-collapsed` class to
        //  the Action Button Container based on the scroll position.
        //  This is needed mostly for touch devices to display the button label
        //  when the user is at the top of the page and hide it when scrolling down.
        document.addEventListener('scroll', debounce(() => requestAnimationFrame(
            () => (
                window.scrollY > scrollOffset ? this.element.classList.add('is-collapsed') : this.element.classList.remove('is-collapsed')
            ),
        ), { delay: 250 }));
    }

}

ActionButtonContainer.register();

export default ActionButtonContainer;
