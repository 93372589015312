/**
 *
 * @param fn {function}
 * @param [options] {options}
 * @param [options.delay=100] {number}
 * @param [options.that] {object}
 * @returns {debouncedFn}
 */
export default function debounce(fn, options = {}) {
    let timestamp;
    let timeout;
    let that;
    let args;
    const later = () => {
        const last = Date.now() - timestamp;

        if (last < options.delay) {
            timeout = setTimeout(later, options.delay - last);
        } else {
            timeout = null;
            fn.apply(that, args);
        }
    };

    const opts = { delay: 100, ...options };

    return function debouncedFn() {
        timestamp = Date.now();

        args = arguments;
        that = opts.that || this;

        if (!timeout) {
            timeout = setTimeout(later, opts.delay);
        }
    };
}
