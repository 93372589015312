/* ------------------------------------*\
    #ACCORDION SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';
import AnalyticsHelper from '../../../../../src/js/module/analytics-helper';

class Accordion extends PanelGroup {

    static componentName = 'accordion';

    static componentSelector = '.js-accordion';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.js-accordion__title-label',
        panelSelector: '.js-accordion__panel',
        btnWrapper: '.js-accordion__title',
        btnWrapperClick: true,
        btnAction: 'toggle',
    });

    constructor() {
        super(...arguments);
        this.analyticsHelper = new AnalyticsHelper(this.$$element, 'collapsible');

        /**
         * Listen to panelchange events on the panels and react to them regarding tracking.
         */
        this.$$panels.on('bronson_panelchanged', this.updateTrackingState.bind(this));

        /**
         * When transition has ended
         */
        this.$$panels.on('transitionend', ({ target }) => {
            const button = target?.previousElementSibling?.closest('.js-accordion__title');
            const { multiple } = this.options;

            /**
             * Add class when transition has finished as trigger that can
             * be used in CSS.
             */
            if (!target.matches('[aria-hidden="true"]')) {
                target.classList.add('is-expanded');
            }

            /**
             * Scroll any active element into view.
             * If the {multiple} option is not set, and the accordion header itself
             * is not in the viewport when expanding a panel, scroll it into the viewport.
             */
            if (!multiple && !target.matches('[aria-hidden="true"]') && !Accordion.isInViewport(button)) {
                button.scrollIntoView(true);
            }
        });

        /**
         * When transition has started
         */
        this.$$panels.on('transitionstart', ({ target }) => {
            if (target.matches('[aria-hidden="true"]')) {
                target.classList.remove('is-expanded');
            }
        });

    }

    /**
     * Based on the open panels we add tracking information to the accordion element.
     */
    updateTrackingState() {
        const allCollapsed = this.$$panels.every((p) => p.getAttribute('aria-hidden') === 'true');

        if (!allCollapsed) {
            this.analyticsHelper.setState();
        } else {
            this.analyticsHelper.unsetState();
        }
    }

    static isInViewport(item) {
        const { top, left, bottom } = item?.getBoundingClientRect() ?? {};
        const { innerWidth, innerHeight } = window;

        return (
            top >= 0 &&
            left >= 0 &&
            left <= innerWidth &&
            bottom <= innerHeight
        );
    }

}

Accordion.register();

export default Accordion;
