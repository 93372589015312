const class2type = {};
const { toString } = class2type;
const hasOwn = class2type.hasOwnProperty;
const fnToString = hasOwn.toString;
const getProto = Object.getPrototypeOf;
const ObjectFunctionString = fnToString.call(Object);

export default function isPlainObject(obj) {
    if (!obj || toString.call(obj) !== '[object Object]') {
        return false;
    }

    const proto = getProto(obj);

    if (!proto) {
        return true;
    }

    const Ctor = hasOwn.call(proto, 'constructor') && proto.constructor;

    return typeof Ctor === 'function' && fnToString.call(Ctor) === ObjectFunctionString;
}
