import Flickity from '../module/flickity-equal-cell-height';
import Component from '../module/component';
import logger from '../module/logger';
import debounce from '../module/debounce';
import libs from '../module/libs';

export class BasicSlider extends Component {

    static componentName = 'basic-slider';

    static componentSelector = '';

    /**
     * [1] Add here your selector that should be excluded from
     *     the `pointerDown` event. This custom event comes from Flickity itself
     *     and in some cases restricts the accessibility of other elements
     *     that are in Flickity. For example, it prevents users from
     *     interacting with the content of tooltips, @see BRON-6613.
     */

    static defaultOptions = {
        slideableClass: 'is-slideable',
        draggableClass: 'is-draggable',
        onInitClass: 'is-initialized',
        elementSelector: '.js-slider__element',
        flickityButtonSelector: '.flickity-prev-next-button',
        flickityDotsSelector: '.flickity-page-dots',
        selectSelector: '', // I.e. .is-default-selected
        pointerDownExcludeSelector: '.js-tooltip, [data-tippy-root]', // [1]
        flickity: {
            initialIndex: '.is-initially-selected',
        },
    };

    constructor() {
        super(...arguments);

        const {
            elementSelector,
            selectSelector,
            cellSelector,
            pointerDownExcludeSelector,
        } = this.options;

        ['resize', 'toggleSliderControls'].forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });

        this.sliderElement = elementSelector ?
            (this.element.querySelector(elementSelector) || this.element) :
            this.element;

        if (this.sliderElement && Flickity) {
            this.flickitySlider = new Flickity(this.sliderElement, this.getFlickityOptions());
        } else if (!Flickity) {
            logger.warn(`WARNING: You are using the "${this.constructor.componentName}" component without its necessary dependency "Flickity".`);
            return;
        }

        if (selectSelector) {
            this.flickitySlider.selectCell(`${selectSelector}${cellSelector}`, null, false);
        }

        // Check if there are elements that should be excluded from
        // the `pointerDown` event and if dragging is enabled by default
        if (
            pointerDownExcludeSelector &&
            this.flickitySlider.options.draggable
        ) {
            // Triggered when the user’s pointer (touch, pointer, cursor) presses down
            this.flickitySlider.on('pointerDown', function (e) {
                // Check if the closest element matches the excluded selector
                if (e.target.closest(pointerDownExcludeSelector)) {
                    // Disable dragging
                    this.options.draggable = false;
                } else {
                    // Enable dragging with at least 2 slides
                    this.options.draggable = '>1';
                }
                // Update dragging
                this.updateDraggable();
            });
        }

        this.flickitySlider.on('select', this.toggleSliderControls);

        this.$$element.on(
            'load',
            debounce(this.resize),
            true,
        );
    }

    /**
     * Toggle state classes if controls are needed.
     */
    toggleSliderControls() {
        const { draggableClass, slideableClass } = this.options;
        const { size, slideableWidth } = this.flickitySlider;

        // Check if all slides fit into slider width.
        const sliderControlsNeeded = (slideableWidth > size.width);

        /**
         * This fixes a bug where Flickity is unable to reassign the draggable behavior when resizing from a big to small
         * viewport when `groupCells` or `contain` options are set.
         * The fix only kicks in when the `draggable` option {@link https://flickity.metafizzy.co/options.html#draggable} in Flickity was set.
         * @see https://github.com/metafizzy/flickity/issues/960
         */
        if (this.flickitySlider.options.draggable) {
            this.flickitySlider.isDraggable = sliderControlsNeeded;
            this.$$element.toggleClass(draggableClass, sliderControlsNeeded);
        }

        this.$$element.toggleClass(slideableClass, sliderControlsNeeded);
    }

    resize() {
        this.flickitySlider.resize();
    }

    getFlickityOptions() {
        const { flickity, onInitClass } = this.options;
        return {
            ...flickity,
            ...{
                on: {
                    ready: () => this.element.classList.add(onInitClass),
                },
            },
        };
    }

}

BasicSlider.register();

export default class Slider extends BasicSlider {

    static componentName = 'slider';

    static componentSelector = '.js-slider';

    static defaultOptions = BasicSlider.extendDefaultOptions({
        flickity: {
            cellSelector: '.js-slider__item',
            pageDots: true,
            prevNextButtons: true,
            groupCells: true,
            cellAlign: 'left',
            contain: false,
            freeScroll: true,
        },
    });

}

Slider.register();

if (Flickity) {
    libs.Flickity = Flickity;
    //@Todo: re-add Flickity to global namespace?
}
