import componentsRegister from './component-register';
import componentExpando from './component-expando';
import globalOptions from './global-options';
import $$ from './item-collection';
import './$$_component';
import camelCase from './camel-case';
import extend from './extend';
import logger from './logger';

export default class BronsonComponent {

    static $$ = $$;

    static defaultOptions = {};

    static extendDefaultOptions(...source) {
        return extend({}, this.defaultOptions, ...source);
    }

    static register(component, warn) {
        return componentsRegister.register(component || this, warn);
    }

    static init(initOptions) {
        this.register(null, true);

        this.dataPrefix = `data-${this.componentName.toLowerCase()}-`;

        if (globalOptions.legacyFix) {
            this.legacyFix();
        }

        if (this.componentSelector == null) {
            logger.error(`component class ${this.componentName} has no "componentSelector" defined.`);
            return;
        }

        if (!this.componentSelector) {
            return;
        }

        $$(this.componentSelector).component(this.componentName, initOptions);
    }

    static legacyFix() {
        // empty
    }

    constructor(element, initOptions) {
        this.element = element;
        this.$$element = $$(element);

        element[componentExpando][this.constructor.componentName] = this;

        this.options = this.getOptions(initOptions);
    }

    getOptions(initOptions = this.options || {}) {
        const markupOptions = {};

        const { attributes } = this.element;

        for (let i = 0, len = attributes.length; i < len; i++) {
            const name = attributes[i].nodeName;

            if (name.startsWith(this.constructor.dataPrefix)) {
                const optionName = camelCase(name.replace(this.constructor.dataPrefix, ''));

                try {
                    markupOptions[optionName] = JSON.parse(attributes[i].nodeValue);
                } catch (er) {
                    markupOptions[optionName] = attributes[i].nodeValue;
                }
            }
        }

        return extend({}, this.constructor.defaultOptions, initOptions, markupOptions);
    }

    /**
     * Allows setting options for components after they are initialized.
     * @param {Object} options
     *
     * @example
     * ```js
     * bronson.$$(<selector>).component('<component>').setOptions({})
     * ```
     */
    setOptions(options) {
        const _options = this.getOptions();
        this.options = extend({}, _options, options);
    }

    query() {
        return this.$$element.query(...arguments);
    }

    trigger(type, ...args) {
        return this.$$element.triggerFirst(`bronson_${type}`, ...args);
    }

}
