/* ------------------------------------*\
    #FAV-BUTTON SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';

/**
 * The FavoriteButton component controls a toggle-like favorite button
 * @extends Component
 */
export default class FavoriteButton extends Component {

    static componentName = 'fav-button';

    static componentSelector = '.js-fav-button';

    static defaultOptions = {};

    constructor() {
        super(...arguments);
        /**
         * Assign the click handler to the button element.
         */
        this.$$element.on('click', (event) => {
            event.preventDefault();
            // Clicking the button just inverts its `aria-pressed` state
            this.toggle();
        });

        /**
         * Monkey patch missing a11y attributes on the button.
         * Currently, we have no  `js-*` class selector at hand.
         * @deprecated Remove in v15.
         */
        const favIcon = this.element.querySelector('.c-fav-button__icon');
        if (favIcon?.hasAttribute('aria-hidden')) {
            favIcon?.setAttribute('aria-hidden', true);
            favIcon?.setAttribute('role', 'presentation');
        }
    }

    /**
     * Toggle the button state, namely the `aria-pressed` attribute.
     * According to WAI the attribute must be set with either its respective values.
     * @see https://www.w3.org/WAI/PF/aria/states_and_properties#aria-pressed
     */
    toggle() {
        /**
         * @deprecated Remove in v15.
         */
        if (this.element.hasAttribute('aria-pressed')) {
            const nextState = this.element.getAttribute('aria-pressed') === 'true';
            this.element.setAttribute('aria-pressed', String(!nextState));
        }
        this.element.classList.toggle('is-active');
    }

}

FavoriteButton.register();
