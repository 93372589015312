/* ------------------------------------*\
    #TABS SLIDER SCRIPT
\*------------------------------------ */

import '../../../../src/js/components/slider';
import PanelGroup from '../../../../src/js/components/panelgroup';
import debounce from '../../../../src/js/module/debounce';

class Tabs extends PanelGroup {

    static componentName = 'tabs';

    static componentSelector = '.js-tabs';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.js-tabs__nav-link',
        panelSelector: '.js-tabs__content',
        btnWrapper: '.js-tabs__nav-link',
        defaultSelectedIndex: 0,
        btnAction: 'expand',
        debounceThreshold: 200,
        tabbedInterface: true,
        cellAlign: 'left',
    });

    constructor() {
        super(...arguments);

        this.tabsSlider = this.initTabsSliderContainer();

        if (!this.tabsSlider) {
            console.warn('WARNING: You are using "Tabs" without its necessary dependency "Flickity".');
            return;
        }

        // This ensures slider recalculate when the tabs change its size,
        // e.g. during font-weight or additional content is shown inside.
        this.$$buttons.on('click', () => this.tabsSlider.resize());

        /**
         * Keep the active tab in viewport.
         */
        window.addEventListener(
            'resize',
            debounce(
                () => {
                    /**
                     * Because the slider is using the `groupCells` option,
                     * we cannot use the `selectedIndex` as guidance and need to find the active button.
                     * @type {*|number}
                     */
                    const activeIndex =
                        this.tabsSlider.cells.findIndex((slide) => slide.element.querySelector(
                            '.js-tabs__nav-link.is-active',
                        )) ?? 0;
                    /**
                     * Instantly select the new index.
                     */
                    this.tabsSlider.selectCell(activeIndex, false, true);
                },
                { delay: this.options.debounceThreshold },
            ),
        );
    }

    /**
     * Initializes the tab slider.
     * @return {Flickity} - Returns the slider instance.
     */
    initTabsSliderContainer() {
        const tabsSlider = this.query('.js-tabs__nav')
            .component('basic-slider', {
                flickity: {
                    cellSelector: '.js-tabs__nav-item',
                    contain: true, // Prevent excess scroll at beginning or end of the slider
                    pageDots: false,
                    cellAlign: this.options.cellAlign,
                    groupCells: true,
                    freeScroll: true,
                    prevNextButtons: true,
                    on: {
                        ready() {
                            setTimeout(() => {
                                tabsSlider.resize();
                            }, 1000);
                        },
                    },
                },
            })
            .getFirst('flickitySlider');
        return tabsSlider;
    }

}

Tabs.register();

export default Tabs;
