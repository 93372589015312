/* eslint-disable class-methods-use-this */
import Component from './component';
/**
 * @TODO: Remove the `container-query-observer.js` once native container queries are supported.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
 */
import cq from './container-query';

export class ContainerQuery extends Component {

    static componentName = 'container-query';

    // Adding individual component selectors here is a bit dirty, but it keeps
    // the markup clean and allows us to use real container queries (or a polyfill)
    // at a later date.
    static componentSelector = `
        [data-container-query],
        .c-description-list--split,
        .c-product-table,
        .c-price-box,
        .c-item-teaser--row
    `;

    static defaultOptions = {
    };

    constructor() {
        super(...arguments);

        /**
         * Gracefully degrade on older browsers where
         * {@link ResizeObserver} is not available.
         */
        if (window.ResizeObserver) {
            this.observer = new ResizeObserver(this.observeEntries.bind(this));
            this.observer.observe(this.element);
        }
    }

    /**
     * The method to call upon observing the {this.target}.
     * @param entries {ResizeObserverEntry[]} - An array of observed entries.
     */
    observeEntries(entries) {
        // This array only holds one element on initialization.
        // More elements only if added via `this.observe`.
        entries
            .filter(({ target }) => cq.hasSizeData(target))
            .forEach((entry) => cq.setBreakpoints(entry));
    }

    /**
     * Initiates the observing of a specified entry.
     * @param {HTMLElement} target
     */
    observe(target) {
        if (cq.hasSizeData(target)) {
            this.observer.observe(target);
        } else {
            // eslint-disable-next-line no-console
            console.log('Element not configured for container queries. Make sure the element has `--container-widths` and/or `--container-heights` CSS custom property defined.');
        }
    }

    /**
     * Ends the observing of a specified entry.
     * @param {*} target
     */
    unobserve(target) {
        this.observer.unobserve(target);
    }

    /**
     * Unobserves all observed entries.
     */
    disconnect() {
        this.observer.disconnect();
    }

}

ContainerQuery.register();

export default ContainerQuery;
