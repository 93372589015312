/* ------------------------------------*\
    #CARD CONTENT SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../src/js/components/panelgroup';
import Accordion from '../55-accordion/10-accordion/accordion';

class CardExpandable extends PanelGroup {

    static componentName = 'card-expandable';

    static componentSelector = '.js-card-expandable';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.js-card-expandable__trigger',
        panelSelector: '.js-card-expandable__panel',
        btnAction: 'toggle',
        parentToggleClass: 'is-open',
        panelChangeEvent: 'expandable-card-changed',
    });

    constructor() {
        super(...arguments);

        /**
         * Allow nested accordions to work for inside the expandable card variant
         * by using a custom event but hook into the event of the accordion to react on panel changes.
         */
        this.$$panels.on(`bronson_${Accordion.defaultOptions.panelChangeEvent}`, ({ currentTarget }) => {
            if (currentTarget.getAttribute('aria-hidden') === 'false') {
                currentTarget.style.height = 'auto';
            }
        });
    }

}

CardExpandable.register();

export default CardExpandable;
