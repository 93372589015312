/* ------------------------------------*\
    #BACK-TO-TOP SCRIPT
\*------------------------------------ */

import Component from '../../../../../src/js/module/component';

export class BackToTop extends Component {

    static componentName = 'back-to-top';

    static componentSelector = '.js-back-to-top';

    static defaultOptions = {
        triggerSelector: '.js-back-to-top-trigger',
    };

    constructor() {
        super(...arguments);
        this.$$trigger = this.$$element.query(this.options.triggerSelector);

        /**
         * Scroll to the top of the page when the trigger is clicked.
         */
        this.$$trigger.on('click', (e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
        });
    }

}

BackToTop.register();

export default BackToTop;
