/* ------------------------------------*\
    #CAROUSEL SCRIPT
\*------------------------------------ */

import '../../../../src/js/components/slider';
import Component from '../../../../src/js/module/component';

// Since we need to reinitialize the component with modified options, we cannot
// simply extend the `BasicSlider` class.
export default class Carousel extends Component {

    static componentName = 'carousel';

    static componentSelector = '.js-carousel';

    static defaultOptions = {
        defaultAutoplayDuration: 5000,
        flickity: {
            cellSelector: '.c-carousel__item',
            imagesLoaded: true,
        },
    };

    constructor() {
        super(...arguments);

        // We need to overwrite the options and then reinitialize the component
        this.modifyOptions();

        [this.sliderComponent] = this.$$element.component('basic-slider', this.options).items;
        this.flickitySlider = this.sliderComponent.flickitySlider;

        /**
         * Since the carousel deals with images, we need to try to
         * optimize loading the best possible candidate by adjusting the
         * current slides image’s `[sizes]` attribute.
         * @see https://jira.platform.vwfs.io/browse/BRON-6972
         */
        this.sliderComponent.flickitySlider.on('fullscreenChange', (isFullscreen) => {
            const images = this.sliderComponent.element?.querySelectorAll('img[sizes]') ?? [];
            /**
             * Iterate over each image and rewrite/restore its sizes.
             */
            images.forEach((image) => {
                /**
                 * If the current slide has an image we adjust its `[sizes]` attribute.
                 * If entering fullscreen mode we store the original slot size in `[data-flickity-origin-sizes]`
                 * attribute. On fullscreen-exit we restore this value for the `[sizes]` attribute.
                 */
                if (isFullscreen) {
                    const originalSizes = image.getAttribute('sizes');
                    image.setAttribute('sizes', '100vw');
                    image.setAttribute('data-carousel-origin-sizes', originalSizes);
                } else {
                    const originalSizes = image.getAttribute('data-carousel-origin-sizes');
                    image.setAttribute('sizes', originalSizes);
                    image.removeAttribute('data-carousel-origin-sizes');
                }
            });
        });
    }

    /**
     * Overwrite the default options object and add user-defined options to the
     * flickity object.
     *
     * @return {void}
     */
    modifyOptions() {
        const { options } = this;
        const {
            flickity,
            defaultAutoplayDuration,
        } = options;

        // Add any key-value pair passed by the user to the flickity options
        // object and remove it from the component options object
        for (const key in options) {
            if (Object.prototype.hasOwnProperty.call(options, key) &&
                !Object.prototype.hasOwnProperty.call(Carousel.defaultOptions, key)
            ) {
                flickity[key] = options[key];

                delete options[key];
            }
        }

        // Set the default auto-play duration, if `flickity.autoPlay` is `true`
        if (flickity.autoPlay === true) {
            flickity.autoPlay = defaultAutoplayDuration;
        }
    }

}

Carousel.register();
