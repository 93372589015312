/* ------------------------------------*\
    #MAIN JS FILE BRONSON-DEFAULT
\*------------------------------------ */

import Utils from './utils';

import ComponentRegister from './module/component-register';
import bronsonDefaultExport from './module/bronson-default-export';
import './module/scroll';
import './module/table-aria-roles';
import './module/table-sticky';
import './module/container-query-observer';
import './module/float-grid';
import './module/sticky-element-size';
import './components/overlay';
import '../../styleguide/bronson-patterns/components/65-interaction/10-interaction-layer/interaction-layer';
import '../../styleguide/bronson-patterns/components/65-interaction/30-inline-editable/inline-editable';
import '../../styleguide/bronson-patterns/components/55-accordion/10-accordion/accordion';
import '../../styleguide/bronson-patterns/components/55-accordion/30-tree-view/tree-view';
import '../../styleguide/bronson-patterns/components/50-tabs/tabs';
import '../../styleguide/bronson-patterns/components/40-notifications/10-notification/notification';
import '../../styleguide/bronson-patterns/components/40-notifications/11-context-notification/context-notification';
import '../../styleguide/bronson-patterns/components/40-notifications/50-tooltip/tooltip';
import '../../styleguide/bronson-patterns/components/40-notifications/15-toast-notification/toast-notification';
import '../../styleguide/bronson-patterns/components/32-form-elements/05-form-field/floating-label';
import '../../styleguide/bronson-patterns/components/36-navigation/20-site-nav/site-nav';
import '../../styleguide/bronson-patterns/components/36-navigation/30-section-nav/section-nav';
import '../../styleguide/bronson-patterns/components/40-notifications/12-notification-center/notification-center';
import '../../styleguide/bronson-patterns/components/60-modal/modal';
import '../../styleguide/bronson-patterns/components/65-interaction/40-favorite-button/favorite-button';
import '../../styleguide/bronson-patterns/components/32-form-elements/60-datepicker/datepicker';
import '../../styleguide/bronson-patterns/components/32-form-elements/61-timepicker/timepicker';
import '../../styleguide/bronson-patterns/components/32-form-elements/80-form-section/form-section';
import '../../styleguide/bronson-patterns/components/01-global/25-footer/footer-accordion';
import '../../styleguide/bronson-patterns/components/32-form-elements/51-custom-range-slider/custom-range-slider';
import '../../styleguide/bronson-patterns/components/32-form-elements/52-custom-progress/custom-progress';
import '../../styleguide/bronson-patterns/components/36-navigation/70-wizard/wizard';
import '../../styleguide/bronson-patterns/components/82-carousel/carousel';
import '../../styleguide/bronson-patterns/components/65-interaction/50-fold-out/fold-out';
import '../../styleguide/bronson-patterns/components/65-cards/card-expandable';
import '../../styleguide/bronson-patterns/components/72-container/40-sticky-container/sticky-container';
import '../../styleguide/bronson-patterns/components/75-media/10-video/video';
import '../../styleguide/bronson-patterns/components/20-buttons/50-back-to-top/back-to-top';
import '../../styleguide/bronson-patterns/components/72-container/50-expandable-content/expandable-content';
import '../../styleguide/bronson-patterns/components/72-container/50-compare-and-select/compare-and-select';
import '../../styleguide/bronson-patterns/components/20-buttons/40-action-buttons-container/action-buttons-container';
import '../../styleguide/bronson-patterns/components/65-cards/card-responsive-collapse';
import '../../styleguide/bronson-patterns/components/65-interaction/20-dropdown-container/dropdown-container';
import './components/toggle';
import './components/drop-down';
import './components/combobox';
import './components/scroll-observer';

// Define global bronson object.
const bronson = Object.assign(bronsonDefaultExport, {
    init() {
        bronsonDefaultExport.$$(() => {
            ComponentRegister.initAll();
        });
    },
    Utils,
});

// Initialize Bronson.
bronson.init();

// Export important modules to be used from the outside.
export default bronson;
