/* ------------------------------------*\
    #TOGGLE SCRIPT
\*------------------------------------ */
import Component from '../module/component';

export default class Toggle extends Component {

    static componentName = 'toggle';

    static componentSelector = '.js-toggle';

    static defaultOptions = Component.extendDefaultOptions({
        /**
         * The target is not a direct child of `.o-toggle`
         * so we do not use a BEM element like `.o-toggle__target` here.
         */
        targetClass: 'js-toggle-target',
        hidden: true,
    });

    constructor() {
        super(...arguments);
        /**
         * Initialize component
         */
        this.init();
    }

    /**
     * Handle trigger interactions.
     * @param event
     */
    handleClick(event) {
        event.preventDefault();
        event.stopPropagation();
        const hidden = this.target.getAttribute('aria-hidden');
        this.target.setAttribute('aria-hidden', hidden === 'true' ? 'false' : 'true');
        if (hidden !== 'true') {
            const { y } = this.target.getBoundingClientRect();
            /**
             * Scroll back into view when scrolled outside.
             */
            if (y < 0) {
                this.target.scrollIntoView(true);
            }
        }
        this.relatedButtons.forEach((button) => {
            button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
        });
    }

    /**
     * Initialize component.
     * @returns {boolean}
     */
    init() {
        if (!this.options?.targetId) {
            // eslint-disable-next-line no-console
            console.warn('Toggle: No target [id] was provided.');
            return false;
        }
        const { hidden, targetId } = this.options;

        /**
         * Query targets.
         */
        this.target = document.querySelector(`#${targetId}`);
        this.relatedButtons = Array.from(document.querySelectorAll(`${Toggle.componentSelector}[data-toggle-target-id="${targetId}"]`));

        /**
         * If no target was found we bail out.
         */
        if (!this.target) {
            // eslint-disable-next-line no-console
            console.warn(`Toggle: No target found for [id]: '${targetId}'`);
            return false;
        }

        /**
         * Setup mandatory attributes.
         */
        this.element.setAttribute('id', this.options.id);
        this.element.setAttribute('aria-controls', targetId);
        this.element.setAttribute('aria-expanded', !hidden ?? 'false');
        const targetLabel = this.relatedButtons.reduce((prev, curr) => `${prev} ${curr.id}`.trim(), '');
        this.target.classList.add(this.options.targetClass);
        this.target.setAttribute('aria-labelledby', targetLabel);

        /**
         * Handle default state.
         */
        this.target.setAttribute('aria-hidden', hidden);

        /**
         * Assign event handler only for this component.
         */
        this.element?.addEventListener('click', this.handleClick.bind(this));
    }

}

Toggle.register();
