/* ------------------------------------*\
    #TIMEPICKER SCRIPT
\*------------------------------------ */

import flatpickr from 'flatpickr/dist/flatpickr'; // use non-esm
import Component from '../../../../../src/js/module/component';
import libs from '../../../../../src/js/module/libs';

export default class Timepicker extends Component {

    static componentName = 'timepicker';

    static componentSelector = '.js-timepicker';

    static flatpickr = flatpickr;

    static defaultOptions = {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        wrap: true,
    };

    constructor() {
        super(...arguments);

        if (!flatpickr) {
            console.warn('WARNING: You are using "Timepicker" without its necessary dependency "flatpickr".');
            return;
        }

        // Array of flatpickr event hooks. For detailed information see https://flatpickr.js.org/events/.
        const flatpickrEventHooks = [
            'onChange',
            'onClose',
            'onOpen',
            'onReady',
            'onValueUpdate',
            'onDayCreate',
        ];

        // For each flatpickr event hook, we dispatch a Bronson-specific event. The names
        // of these events start with "bronson_timepicker" and end with the name of the
        // corresponding flatpickr event (without "on"). So "onOpen" will dispatch the
        // event "bronson_timepickerOpen".
        flatpickrEventHooks.forEach((type) => {
            const that = this;

            this.options[type] = (...args) => {
                that.trigger(type.replace(/^on/, that.constructor.componentName), { args });
            };
        });

        // Fix issue on mobile devices when "readonly" attribute is used for the input
        // field. If we do not set "disableMobile = true", the user can open the native
        // timepicker by clicking on the input field.
        const isReadOnly = this.query('.c-input__input').getFirst('readOnly');

        if (isReadOnly) {
            this.options.disableMobile = true;
        }

        this.flatpickr = flatpickr(this.element, this.options);
    }

}

Timepicker.register();

if (flatpickr) {
    libs.flatpickr = flatpickr;
}
