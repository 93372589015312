import Component from '../../../../../src/js/module/component';

class FloatingLabel extends Component {

    static componentName = 'floating-label';

    static componentSelector = '.js-floating-label';

    static defaultOptions = {
        formfield: '.c-form-field:not(.c-form-field--static)',
        activeClass: 'is-active',
        disabledClass: 'is-disabled',
        readOnlyClass: 'is-readonly',
        successClass: 'is-success',
        errorClass: 'is-error',
    };

    constructor() {
        super(...arguments);

        ['update', 'updateAll'].forEach((fn) => {
            this[fn] = this[fn].bind(this);
        });

        this.$$element
            .addClass('is-js-on')
            .on('change', ({ target }) => {
                this.update(target);
            })
            .on('reset', () => {
                setTimeout(this.updateAll, 0);
            });
        this.updateAll();
    }

    update(target) {
        FloatingLabel.$$(target)
            .callAll('closest', this.options.formfield)
            .filter((element) => element)
            .toggleClass(this.options.activeClass, !!target.value);
    }

    updateAll() {
        FloatingLabel.$$(this.options.formfield)
            .query('input, select, textarea').forEach((elem) => {
                this.syncInputStates(elem);
                this.update(elem);
            });
    }

    /**
     * Syncs states from the inner form input to the outer form field.
     */
    syncInputStates(target) {
        const {
            disabledClass, readOnlyClass, successClass, errorClass, formfield,
        } = this.options;
        FloatingLabel.$$(target)
            .callAll('closest', formfield)
            .filter((element) => element)
            .forEach((element) => {
                if (target.classList.contains(successClass)) {
                    element.classList.add(successClass);
                }
                if (target.classList.contains(errorClass)) {
                    element.classList.add(errorClass);
                }
                if (target.hasAttribute('disabled')) {
                    element.classList.add(disabledClass);
                }
                if (target.hasAttribute('readonly')) {
                    element.classList.add(readOnlyClass);
                }
            });
    }

}

FloatingLabel.register();
