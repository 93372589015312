/* ------------------------------------*\
    #FOLD-OUT SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

class FoldOut extends PanelGroup {

    static componentName = 'fold-out';

    static componentSelector = '.js-fold-out';

    static defaultOptions = {
        btnSelector: '.js-fold-out__toggle',
        panelSelector: '.js-fold-out__content',
        btnAction: 'toggle',
        multiple: false,
        defaultCollapsed: true,
        animateHeightClass: '',
    };

    constructor() {
        super(...arguments);
        this.$$panels.on('bronson_panelchanged', () => {
            const { top } = this.element.getBoundingClientRect();
            /**
             * Scroll to top of element on collapse of fold out and only if scrolled down.
             */
            if (this.selectedIndex === -1 && top < 0) {
                /**
                 * Use `scrollIntoView()` when supported otherwise use `scrollTo()` for progressive enhancement.
                 */
                if (!Element.prototype.scrollIntoView) {
                    window.scrollTo(0, this.element.offsetTop);
                } else {
                    this.element.scrollIntoView(true);
                }
            }
        });
    }

    toggle(elementOrIndex, ...args) {
        return this._callPanelMethod(elementOrIndex, 'toggle', ...args);
    }

}

FoldOut.register();

export default FoldOut;
