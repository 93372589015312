import logger from './logger';

const componentRegister = {};

export default {
    get(name) {
        const component = name ? componentRegister[name] : componentRegister;

        if (!component) {
            logger.warn(`component ${name} not found. Make sure to import and register it using Component.register()`);
        }

        return component;
    },
    register(componentClass, lateRegister) {
        const name = (componentClass.componentName || componentClass.name).toLowerCase();

        if (lateRegister && !componentRegister[name]) {
            logger.error(`You forgot to register your ${name} component in advance.`);
        }

        if (componentRegister[name] && componentRegister[name] !== componentClass) {
            logger.error(`There is already a component registered with the same name ${name}.`, componentClass, componentRegister[name]);
        }

        if (!componentClass.componentName) {
            logger.info(`No explicit componentName set componentName. We use ${name} as componentName.`);
        }

        componentClass.componentName = name;

        componentRegister[name] = componentClass;

        return componentClass;
    },

    initAll() {
        Object.values(componentRegister).forEach((ComponentClass) => {
            ComponentClass.init();
        });
    },
};
