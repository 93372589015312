/* ------------------------------------*\
    #SECTION-NAV SCRIPT
\*------------------------------------ */

import {
    SCROLL_OBSERVER_EVENT,
    SCROLL_OBSERVER_SKIP_OBSERVE_ATTR,
} from '../../../../../src/js/components/scroll-observer';
import BronsonComponent from '../../../../../src/js/module/component';

export default class SectionNav extends BronsonComponent {

    static componentName = 'section-nav';

    static componentSelector = '.js-section-nav';

    static defaultOptions = BronsonComponent.extendDefaultOptions({
        sectionContainerSelector: '.js-section-nav__container',
        sectionListSelector: '.js-section-nav__list',
        sectionListOverflowLeftClass: 'has-overflow-left',
        sectionListOverflowRightClass: 'has-overflow-right',
        scrollPrevSelector: '.js-section-nav__scroll-button--prev',
        scrollNextSelector: '.js-section-nav__scroll-button--next',
        linkSelector: '.js-section-nav__link',
        activeSectionLabelSelector: '.js-section-nav__section-label',
        activeSectionLabelTextSelector: '.js-section-nav__section-label__text',
        preventScrollClass: 'c-section-nav-prevent-scroll',
        sectionNavVisibleClass: 'is-visible',
        sectionNavActiveClass: 'is-active',
        sectionNavBreakpointCustomProp: '--bron-section-nav-breakpoint',
        /**
         * The overflowThreshold defines an additional amount of pixels
         * an element must pass before it is being considered to be
         * in the parent’s scroll overflow.
         */
        overflowThreshold: 10,
        /**
         * Defines the time to wait after scrolling the list. Sometimes it
         * could be beneficial if a larger time is set in order make
         * the scrolling happen more prominently.
         */
        scrollDelay: 1000,
        scrollDelayShort: 250,
    });

    constructor() {
        super(...arguments);

        const {
            linkSelector,
            sectionListSelector,
            scrollPrevSelector,
            scrollNextSelector,
            sectionContainerSelector,
            activeSectionLabelSelector,
            activeSectionLabelTextSelector,
        } = this.options;

        /**
         * Check if the window has an anchor navigation.
         */
        this.hasHashNavigation = !!window?.location?.hash;

        /**
         * Query related elements.
         */
        this.activeSectionLabel = this.element.querySelector(activeSectionLabelSelector);
        this.activeSectionLabelText = this.element.querySelector(activeSectionLabelTextSelector);
        this.sectionContainer = this.element.querySelector(sectionContainerSelector);
        this.sectionList = this.element.querySelector(sectionListSelector);
        this.sectionListParent = this.sectionList.parentElement;
        this.scrollPrevButton = this.element.querySelector(scrollPrevSelector);
        this.scrollNextButton = this.element.querySelector(scrollNextSelector);
        this.links = this.element.querySelectorAll(`${linkSelector}[href*="#"]`);

        /**
         * The scrollOffset defines how many pixels distance to the parent’s
         * left scroll border should be kept in order to not obfuscate elements
         * that are scrolled into the view. Is dynamically set to width of
         * the scroll hint overlay (gradient from white to transparent white
         * to indicate scrolling).
         * @type number
         */
        this.scrollOffset = parseFloat(window.getComputedStyle(this.sectionList, '::before')?.width ?? 0);

        /**
         * Gracefully degrade on older browsers where
         * {@link ResizeObserver} is not available.
         */
        if (window.ResizeObserver) {
            /**
             * Assign a ResizeObserver to handle transitions between small and large viewports.
             * @type {ResizeObserver}
             */
            this.ro = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    const breakpoint = parseFloat(getComputedStyle(entry.target)
                        .getPropertyValue(this.options.sectionNavBreakpointCustomProp));
                    this.handleResize(breakpoint);
                }
            });
            this.ro.observe(this.element);
        } else {
            const breakpoint = parseFloat(getComputedStyle(this.element)
                .getPropertyValue(this.options.sectionNavBreakpointCustomProp));
            this.handleResize(breakpoint);
        }

        /**
         * Assign handlers for click and {SCROLL_OBSERVER_EVENT}
         * to the nav links.
         */
        this.links.forEach((link) => {
            /**
             * When we navigate on-page we certainly want to avoid scroll
             * cancellation when we pass other tracked sections.
             */
            link.addEventListener('click', () => this.handleLinkEvent(link));
        });

        /**
         * Hide the mobile nav when clicking outside.
         */
        this.sectionContainer.addEventListener('click', this.handleBackdropEvent.bind(this));

        /**
         * Check if the list has an overflow on either side.
         */
        this.sectionList.addEventListener('scroll', () => this.checkListOverflow(this.sectionList));

        /**
         * When the ScrollObserver emits its {SCROLL_OBSERVER_EVENT}
         * we scroll the respective list items into view.
         */
        this.sectionList.addEventListener(SCROLL_OBSERVER_EVENT, this.scrollListItemIntoView.bind(this));

        /**
         * When the section label button is clicked we toggle the visibility
         * of the container.
         */
        this.activeSectionLabel.addEventListener('click', this.toggleScrollContainer.bind(this));

        /**
         * Assign handler for previous scroll-hint button.
         */
        this.scrollPrevButton.addEventListener('click', () => {
            this.scrollList();
        });

        /**
         * Assign handler for next scroll-hint button.
         */
        this.scrollNextButton.addEventListener('click', () => {
            this.scrollList(-1);
        });

        /**
         * Initially dispatch a ScrollEvent to check for overflow.
         */
        this.checkListOverflow(this.sectionList);
    }

    /**
     * Scrolls the active list item into the view.
     * @param {ScrollObserverEvent} event
     */
    scrollListItemIntoView(event) {
        const { detail: { mostVisibleEntry } } = event;

        /**
         * Check if we have a visible intersection entry in view.
         */
        if (mostVisibleEntry) {
            /**
             * Handle the pool of intersections by only allowing one element
             * to be active.
             */
            const id = mostVisibleEntry?.getAttribute('id');

            /**
             * If the website has a anchor navigation and the section id does not match
             * we do not scroll the list to prevent scroll cancelling on Chrome.
             * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1121151
             * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1043933
             * @see https://bugs.chromium.org/p/chromium/issues/detail?id=833617
             */
            if (this.hasHashNavigation && window?.location?.hash !== `#${id}`) {
                return;
            }

            const link = Array.from(this.links).find((el) => el.getAttribute('href') === `#${id}`);
            this.links.forEach((l) => l.classList.remove(this.options.sectionNavActiveClass));
            link.classList.add(this.options.sectionNavActiveClass);

            /**
             * Check if the list item is visible inside the scrollable list.
             * @type {boolean}
             */
            const listItemInOverflow = this.checkLinkOverflow(link);

            /**
             * Only scroll an item into the view that is activated and
             * we are not in a scroll movement to avoid scroll cancellation
             * in Chrome.
             */
            setTimeout(() => {
                if (
                    link.classList.contains(this.options.sectionNavActiveClass) &&
                    !this.sectionList.hasAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR) &&
                    listItemInOverflow
                ) {
                    requestAnimationFrame(() => {
                        this.sectionList.scrollBy({
                            top: 0,
                            left: (link.offsetLeft - this.sectionList.scrollLeft - this.scrollOffset),
                            behavior: 'smooth',
                        });
                    });
                }
            }, this.options.scrollDelayShort);

            /**
             * Assign the current list item text to the dynamic section label.
             */
            this.activeSectionLabelText.textContent = link.textContent;

            /**
             * Reset the window anchor scrolling.
             * @type {boolean}
             */
            this.hasHashNavigation = false;
        }
    }

    /**
     * Scrolls the list by an fixed amount in either left or right direction.
     * The scroll is approximately 25% of the scroll list’s width each time.
     * @param {number} [direction=1] - The number designating the scroll direction, where 1 is left and -1 is right.
     */
    scrollList(direction = 1) {
        requestAnimationFrame(() => {
            this.sectionList.scrollBy({
                top: 0,
                left: (-(this.sectionList.offsetWidth / Math.abs(this.links.length * 0.25)) * direction),
                behavior: 'smooth',
            });
        });
    }

    /**
     * Checks if the scroll list has an overflow to either horizontal side.
     * @param {HTMLListElement} target
     */
    checkListOverflow(target) {
        const { clientWidth, scrollLeft, scrollWidth } = target;

        /**
         * If the target has a scroll overflow on the left-hand side.
         */
        if (scrollLeft > this.options.overflowThreshold) {
            this.sectionListParent.classList.add(this.options.sectionListOverflowLeftClass);
        } else {
            this.sectionListParent.classList.remove(this.options.sectionListOverflowLeftClass);
        }

        /**
         * If the target has a scroll overflow on the right-hand side.
         */
        if ((scrollWidth - clientWidth) > scrollLeft) {
            this.sectionListParent.classList.add(this.options.sectionListOverflowRightClass);
        } else {
            this.sectionListParent.classList.remove(this.options.sectionListOverflowRightClass);
        }
    }

    /**
     * Checks if the link is in the scroll list overflow under the following conditions:
     * [1] If the link is completely/partially in overflow on the right-hand side.
     * [2] If the link is completely/partially in overflow on the right-hand side.
     * @param {HTMLLinkElement} link
     * @return {boolean}
     */
    checkLinkOverflow(link) {
        return (((link.offsetLeft - this.sectionList.scrollLeft) >= this.sectionList.offsetWidth) ||
            (link.offsetLeft <= this.sectionList.scrollLeft)) || /* [2] */
            ((link.offsetLeft + link.clientWidth) >= this.sectionList.offsetWidth); /* [2] */
    }

    /**
     * Handle click/touch events on the section nav links.
     * @param link
     */
    handleLinkEvent(link) {
        this.sectionList.setAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR, true);

        this.links.forEach((l) => l.classList.remove(this.options.sectionNavActiveClass));
        link.classList.add(this.options.sectionNavActiveClass);
        this.activeSectionLabelText.textContent = link.textContent;

        /**
         * Close the mobile navigation on smaller viewports.
         */
        if (this.sectionContainer.classList.contains(this.options.sectionNavVisibleClass)) {
            /**
             * Assign the current list item text to the dynamic section label.
             */
            this.collapseScrollContainer();
        }

        /**
         * We assume scrolling has finished here.
         */
        setTimeout(() => {
            this.sectionList.removeAttribute(SCROLL_OBSERVER_SKIP_OBSERVE_ATTR);
            const listItemInOverflow = this.checkLinkOverflow(link);

            if (
                link.classList.contains(this.options.sectionNavActiveClass) &&
                listItemInOverflow
            ) {
                requestAnimationFrame(() => {
                    this.sectionList.scrollBy({
                        top: 0,
                        left: (link.offsetLeft - this.sectionList.scrollLeft - this.scrollOffset),
                        behavior: 'smooth',
                    });
                });
            }
        }, this.options.scrollDelay);
    }

    /**
     * Handle clicks on the backdrop and close the mobile navigation.
     * @param event
     */
    handleBackdropEvent(event) {
        if (
            this.sectionContainer.classList.contains(this.options.sectionNavVisibleClass) &&
            event.target.matches(this.options.sectionContainerSelector)
        ) {
            this.collapseScrollContainer();
        }
    }

    /**
     * Expand the scroll list on smaller viewports.
     */
    expandScrollContainer() {
        this.sectionContainer.classList.add(this.options.sectionNavVisibleClass);
        this.activeSectionLabel.setAttribute('aria-expanded', true);

        // Prevent scrolling on body on when section-nav is 'sticky'
        // to avoid scroll-prevention when expanded nav overflows viewport.
        if (this.element.classList.contains('has-target-inside-view')) {
            document.body.classList.add(this.options.preventScrollClass);
        }
    }

    /**
     * Collapse the scroll list on smaller viewports.
     */
    collapseScrollContainer() {
        this.sectionContainer.classList.remove(this.options.sectionNavVisibleClass);
        document.body.classList.remove(this.options.preventScrollClass);
        this.activeSectionLabel.setAttribute('aria-expanded', false);
    }

    /**
     * Show/hide the scroll container.
     */
    toggleScrollContainer() {
        if (this.sectionContainer.classList.contains(this.options.sectionNavVisibleClass)) {
            this.collapseScrollContainer();
        } else {
            this.expandScrollContainer();
        }
    }

    /**
     * Handle resizes for the section list.
     * @param {number} breakpoint - The breakpoint to collapse the smaller viewport list design.
     */
    handleResize(breakpoint) {
        if (window.innerWidth > breakpoint) {
            if (this.sectionContainer.classList.contains(this.options.sectionNavVisibleClass)) {
                this.collapseScrollContainer();
            }
            this.checkListOverflow(this.sectionList);
            this.scrollOffset = parseFloat(window.getComputedStyle(this.sectionList, '::before')?.width ?? 0);
        }
    }

}

SectionNav.register();
