/* ------------------------------------*\
    COMBOBOX

    Reference implementation:
    https://sarahmhigley.com/writing/select-your-poison/
\*------------------------------------ */

import Component from '../module/component';
import * as Utils from './combobox-utils';
import { getActionFromKey } from './combobox-utils';

/**
 * @namespace Combobox
 * @typedef ComboboxOption - Combobox state object.
 * @property {string} label - The option label text.
 * @property {string} value - The option value.
 * @property {number} index - The original zero-based index in the listbox.
 * @property {boolean} selected - Whether the option is currently selected.
 * @property {boolean} disabled - Marks the option as disabled.
 * @property {boolean} hidden - Whether the option is (visually) hidden.
 */

/**
 * @namespace Combobox
 * @typedef ComboboxDefaults - Combobox class defaults.
 * @property {string} [selectItemTextSelector='.c-combobox__item__text'] - The option label text.
 * @property {string} [selectItemFocusClass='is-focused'] - The option value.
 * @property {string} [selectionLabel='Options'] - Whether the option is currently selected.
 * @property {boolean} [valueFormatter=null] - Marks the option as disabled.
 * @property {string} [noOptionsMessage=null] - Optional message to display if no options were found.
 */

export default class Combobox extends Component {

    static componentName = 'combobox';

    static componentSelector = '.js-combobox';

    /**
     * Default options.
     * @type {ComboboxDefaults}
     */
    static defaultOptions = {
        selectItemTextSelector: '.c-combobox__item__text',
        selectItemFocusClass: 'is-focused',
        selectionLabel: 'Options',
        valueFormatter: null,
        noOptionsMessage: 'No options found.',
    };

    constructor() {
        super(...arguments);

        this.comboEl = this.element.querySelector('[role=combobox]');
        this.inputEl = this.element.querySelector('[role="combobox"]');
        this.searchInputEl = this.element.querySelector('.js-combobox-search-input');
        this.valueEl = this.element.querySelector('.js-combobox-value-presentation');
        this.listboxEl = this.element.querySelector('[role=listbox]');
        this.optionEls = Array.from(this.listboxEl.querySelectorAll('[role="option"]') ?? []);
        this.searchInputEl?.setAttribute('autocomplete', 'off');

        // optional element holding selected options
        this.inputValues = this.element.querySelector('.js-combobox__input-values') || null;

        // data
        this.idBase = this.inputEl.id;
        this.selectOptions = this.getOptionsData();

        this.valuePresentation = this.element.querySelector(`#${this.idBase}-values`);
        this.initialPlaceholder = this.valuePresentation.textContent || '';

        // multiple
        this.multiple = (this.listboxEl.getAttribute('aria-multiselectable') === 'true');

        /**
         * Set noOptionsMessage on the listbox element.
         */
        if (this.options.noOptionsMessage) {
            this.listboxEl.setAttribute('data-combobox-no-options-message', this.options.noOptionsMessage);
        }

        /**
         * State objects
         */
        this.selectedOptions = [];
        this.activeIndex = 0;
        this.open = false;

        /**
         * Initiate event handling if the combobox is not disabled or readonly.
         */
        if (!(this.inputEl.hasAttribute('aria-disabled') || this.inputEl.hasAttribute('aria-readonly'))) {
            this.inputEl.addEventListener('focusout', this.onInputBlur.bind(this));
            this.inputEl.addEventListener('click', this.onInputClick.bind(this));
            this.inputEl.addEventListener('keydown', this.onInputKeyDown.bind(this));
            this.searchInputEl?.addEventListener('keyup', this.onSearchInputKeyUp.bind(this));
            this.searchInputEl?.addEventListener('search', this.onSearchInputSearch.bind(this));
            this.initOptionElements();
        }
        this.checkPreSelectedOptions();
    }

    /**
     * Get data from options.
     * @returns {ComboboxOption[]} - Object with option properties.
     */
    getOptionsData() {
        return this.optionEls.map((option, index) => ({
            label: option.querySelector(this.options.selectItemTextSelector).textContent,
            value: option.getAttribute('data-value'),
            selected: (option.getAttribute('aria-selected') === 'true'),
            disabled: (option.getAttribute('aria-disabled') === 'true'),
            hidden: option.hasAttribute('hidden'),
            index,
        }));
    }

    /**
     * Setup option elements
     */
    initOptionElements() {
        // set first item to current by default
        this.optionEls[0].classList.add(this.options.selectItemFocusClass);

        this.optionEls.forEach((optionEl, index) => {
            optionEl.addEventListener('click', () => {
                /**
                 * Get the current index by comparing it to the visible indexes.
                 * @type {number}
                 */
                const getVisibleIndex = this.visibleOptions
                    .findIndex((_optionEl) => _optionEl === this.optionEls[index]);
                this.onOptionClick(getVisibleIndex);
            });

            // prevent triggering 'blur' in input
            optionEl.addEventListener('mousedown', this.onOptionMouseDown.bind(this));
        });
    }

    /**
     * Check if any options are pre-selected and update them accordingly
     */
    checkPreSelectedOptions() {
        this.selectOptions.forEach((option, index) => {
            if (option.selected) {
                this.updateOption(index);
            }
        });
    }

    /**
     * Handler for keyboard input
     * @param {Event} event Input event
     * @returns {function} function based on action
     */
    onInputKeyDown(event) {
        const { key } = event;
        const action = Utils.getActionFromKey(key, this.open);
        const nextIndex = Utils.getUpdatedIndex(this.activeIndex, this.selectOptions.length, action);

        // eslint-disable-next-line default-case
        switch (action) {
            case Utils.MenuActions.Next:
            case Utils.MenuActions.Next10:
            case Utils.MenuActions.Last:
            case Utils.MenuActions.First:
            case Utils.MenuActions.Previous:
            case Utils.MenuActions.Previous10:
                event.preventDefault();
                return this.onOptionChange(nextIndex);
            case Utils.MenuActions.Select:
                event.preventDefault();

                // close menu in non-multiple mode after option selection
                if (!this.multiple) {
                    this.updateMenuState(false);
                }

                return this.updateOption(this.activeIndex);
            case Utils.MenuActions.CloseSelect:
                event.preventDefault();
                this.updateMenuState(false);
                this.updateOption(this.activeIndex);
                return this.resetSearchInput();
            case Utils.MenuActions.Close:
                event.preventDefault();
                this.updateMenuState(false);
                return this.resetSearchInput();
            case Utils.MenuActions.Open:
                this.updateActiveDescendent(this.activeIndex);
                return this.updateMenuState(true);
        }
    }

    /**
     * Handler for click event
     * @param {Event} event Click event
     */
    onInputClick(event) {
        let state;
        const clickOnInput = (event.target === this.inputEl);

        if (!this.open && this.searchInputEl) {
            setTimeout(() => this.searchInputEl.focus(), 100);
        }

        if (this.open && clickOnInput) {
            // close if the input is clicked again to toggle menu
            state = false;
        } else {
            // prevent closing when
            state = this.multiple ? true : clickOnInput;
        }

        this.updateMenuState(state);
    }

    /**
     * Handler for blur event
     * @returns {function|void}
     */
    onInputBlur() {
        if (this.searchInputEl) {
            this.ignoreBlur = false;
            return;
        }

        if (this.open) {
            this.updateMenuState(false, false);
        }
    }

    /**
     * Handler for changing option (set states & attributes)
     * @param {number} index Index of changed option
     */
    onOptionChange(index) {
        this.activeIndex = index;
        this.updateActiveDescendent(index);

        /**
         * Remove any active styles
         */
        this.optionEls.forEach((optionEl) => {
            optionEl.classList.remove(this.options.selectItemFocusClass);
        });

        /**
         * Filter out only visible options
         * @type {HTMLElement}
         */
        const candidate = this.visibleOptions[index];
        candidate?.classList.add(this.options.selectItemFocusClass);

        if (this.open) {
            candidate?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }

    /**
     * Click-Handler for option
     * @param {number} index Index of selected option
     */
    onOptionClick(index) {
        this.onOptionChange(index);
        this.updateOption(index);

        // close menu when not multiple options can be selected
        if (!this.multiple) {
            this.updateMenuState(false);
        }
    }

    /**
     * Mousedown-Handler for option
     */
    onOptionMouseDown() {
        this.ignoreBlur = true;
    }

    /**
     * Handler when option gets selected
     * @param {Event} event Event Object
     */
    onOptionSelection(event) {
        this.updateOption(event.target.selectedIndex);
    }

    /**
     * Check if option is disabled.
     * @param {number} index Index for option to check
     * @returns {boolean}
     */
    getOptionDisabled(index) {
        return this.selectOptions[index].disabled;
    }

    /**
     *
     * @param {number} index Index for option to update
     */
    updateOption(index) {
        // Do not update if option is disabled
        if (this.getOptionDisabled(index)) {
            return;
        }

        const option = this.selectOptions[index];

        // multiple
        if (this.multiple) {
            const selectedIndex = this.selectedOptions
                .findIndex(({ label, value }) => label === option.label && value === option.value);
            const isSelected = selectedIndex > -1;

            if (isSelected) {
                // remove selection from list
                this.selectedOptions.splice(selectedIndex, 1);
                this.updateSelectedOptions(this.selectedOptions);
            } else {
                // add selection to list
                this.updateSelectedOptions([...this.selectedOptions, option]);
            }
        // single
        } else {
            this.selectedOptions = [];
            this.updateSelectedOptions([option]);
        }

        this.updateLabel(option);
    }

    /**
     * Update menu state (open | close)
     * @param {Boolean} open true: open menu, false: close menu
     * @param {Boolean} callFocus Whether input should be focused after updating menu state
     */
    updateMenuState(open, callFocus = true) {
        this.open = open;

        this.comboEl.setAttribute('aria-expanded', `${open}`);

        if (callFocus) {
            if (this.searchInputEl) {
                this.searchInputEl.focus();
            } else {
                this.inputEl.focus();
            }
        }

        /**
         * Reset the search input state on close but delay it to happen 'off-screen'.
         */
        if (!this.open) {
            setTimeout(() => {
                this.resetSearchInput();
            }, 250);
        } else {
            this.updateActiveDescendent(this.activeIndex);
        }
    }

    /**
     * Manage selected options.
     * @param {ComboboxOption[]} options - List of objects with selected options.
     */
    updateSelectedOptions(options) {
        this.selectedOptions = options;

        // update aria-selected attribute on all options
        this.optionEls.forEach((optionEl) => {
            const match = this.selectedOptions.filter((option) => optionEl.getAttribute('data-value') === option.value);
            const optionSelected = !!match.length;
            optionEl.setAttribute('aria-selected', `${optionSelected}`);
        });

        this.storeOptionValues(options);
    }

    /**
     * Update values in hidden input for form context.
     * This is optional and depends on the existence of `input:hidden`.
     * The values can be obtained via `selectedOptions` property.
     * @param {Array} options List of objects with selected options
     */
    storeOptionValues(options) {
        if (this.inputValues) {
            this.inputValues.setAttribute('value', Utils.getValueStringFromOptions(options));
        }
    }

    /**
     * Update label content based on selection.
     * @param {object} option - The current selected option.
     */
    updateLabel(option) {
        /**
         * If no {@link Combobox.options.valueFormatter} was passed we use the {@link Combobox.formatValue} method.
         */
        if (!this.options.valueFormatter) {
            const { length } = this.selectedOptions;
            this.valuePresentation.classList.toggle('has-placeholder', length <= 0);
            this.valuePresentation.textContent = this.formatValue(option);
        } else {
            this.valuePresentation.textContent = this.options.valueFormatter(this.selectedOptions);
        }
    }

    /**
     * Handle keyup in the {@link Combobox.searchInputEl} in the search input field.
     * @param {KeyboardEvent} event - The KeyboardEvent to handle.
     */
    onSearchInputKeyUp(event) {
        const keydownOnSearchInput = event?.target?.classList.contains('js-combobox-search-input');
        const normalizedValue = event?.target?.value?.toLowerCase();
        const action = getActionFromKey(event.key, null);

        /**
         * Check if the keyup originated from the {@link Combobox.searchInputEl} and is not a standard action.
         */
        if (keydownOnSearchInput && action === Utils.MenuActions.AssumedSearchInput) {
            this.optionEls.forEach((optionEl) => (optionEl.getAttribute('data-value').toLowerCase().includes(normalizedValue) ? optionEl.removeAttribute('hidden') : optionEl.setAttribute('hidden', 'true')));
            this.selectOptions = this.getOptionsData().filter((opt) => !opt.hidden);
            this.listboxEl.classList.toggle('has-no-options', this.visibleOptions?.length === 0);

            /**
             * Focus the first element in list, update the active descendent attribute and set the active index on the first match.
             */
            this.visibleOptions.forEach((visibleOptionEl) => visibleOptionEl.classList.remove('is-focused'));
            this.visibleOptions[0]?.classList.add('is-focused');
            this.updateActiveDescendent(0);
            this.activeIndex = 0;
        }
    }

    /**
     * Handle clearing the search input via the `x` pseudo-element button in HTML5.
     * @param {InputEvent} event - The input event to handle.
     */
    onSearchInputSearch(event) {
        if (!event?.target?.value) {
            this.resetSearchInput();
        }
    }

    /**
     * Reset the search input, show all options and refresh the {@link Combobox.selectOptions} collection.
     */
    resetSearchInput() {
        if (this.searchInputEl) {
            this.searchInputEl.value = '';
            this.optionEls.forEach((optionEl) => optionEl.removeAttribute('hidden'));
            this.selectOptions = this.getOptionsData();
            this.listboxEl.classList.remove('has-no-options');
        }
    }

    /**
     * Default value formatting method. Is overloaded via {@link Combobox.options.valueFormatter}.
     * @param {ComboboxOption} option
     * @returns {*|string|string}
     */
    formatValue(option) {
        const { length } = this.selectedOptions;
        if (this.multiple) {
            /**
             * Show placeholder instead of `(0) Options`.
             */
            return length ? `(${length}) ${this.options.selectionLabel}` : this.initialPlaceholder;
        }
        return option?.label;
    }

    /**
     * Update the `[aria-activedescendent]` attribute.
     * @param {number|undefined} [index]
     */
    updateActiveDescendent(index) {
        const activeDescendantId = this.visibleOptions[index]?.id;
        this.inputEl.setAttribute('aria-activedescendant', activeDescendantId ?? '');
    }

    /**
     * Filter out the visible options.
     * @type {HTMLElement[]}
     */
    get visibleOptions() {
        return this.optionEls.filter((_optionEl) => !_optionEl.hasAttribute('hidden'));
    }

}

Combobox.register();
