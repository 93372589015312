/* ------------------------------------*\
    #VIDEO PLAYER SCRIPT
\*------------------------------------ */

import Plyr from 'plyr/dist/plyr';
import Component from '../../../../../src/js/module/component';
import logger from '../../../../../src/js/module/logger';
import libs from '../../../../../src/js/module/libs';

const EffectiveConnectionTypeEnum = Object.freeze({
    'slow-2g': 0,
    '2g': 1,
    '3g': 2,
    '4g': 3,
});

export default class VideoPlayer extends Component {

    static componentName = 'video';

    static componentSelector = '.js-video';

    static defaultOptions = {
        debug: false,
        autoplay: false,
        invertTime: false,
        controls: [
            'play',
            'play-large',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'fullscreen',
        ],
        fullscreen: {
            fallback: true,
        },
        youtube: {
            noCookie: true,
        },
        urls: {
            youtube: {
                api: '', // Disable YouTube API (for title/aspect ratio calculation) calls through third-party noembed.com service.
            },
        },
        autoplayMinimumConnection: null,
    };

    constructor() {
        super(...arguments);
        /**
         * Cache the play/pause button.
         * @type {Element}
         */
        this.videoControl = document.querySelector('.js-background-video-control');

        /**
         * Track if the video was stopped via a user interaction or via connectivity/visibility APIs.
         * @type {boolean}
         */
        this.userForcedPause = false;

        /**
         * Track if the video had already been played.
         * @type {boolean}
         */
        this.didPlay = false;

        /**
         * Check if {@link Plyr} lib is present and only proceed if so.
         */
        if (Plyr) {
            if (this.options.autoplay == null) {
                this.options.autoplay = this.element.autoplay;
            }

            if (this.options.muted == null) {
                this.options.muted = this.element.muted;
                if (this.options.muted) {
                    /**
                     * Make plyr respect `[muted]`.
                     * @see https://github.com/sampotts/plyr/issues/1495#issuecomment-659735226
                     * @type {{enabled: boolean}}
                     */
                    this.options.storage = { enabled: false };
                    this.options.volume = 0;
                }
            }

            /**
             * Create a new {@link Plyr} instance.
             */
            this.plyr = new Plyr(this.element, this.options);

            /**
             * Get the current network information – only supported via Chromium for now.
             * @type {NetworkInformation|*}
             */
            this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

            /**
             * Get the `prefers-reduced-motion` media query setting.
             * @type {MediaQueryList}
             */
            const reduceMotionMediaQueryList = window.matchMedia('(prefers-reduced-motion: reduce)');
            const prefersReducedMotion = reduceMotionMediaQueryList?.matches;

            /**
             * Bail out if users prefer reduced motion.
             */
            if (prefersReducedMotion) {
                this.pause();
            }

            /**
             * Add a listener for {@link MediaQueryListEvent} to handle media reduced motion media changes.
             */
            reduceMotionMediaQueryList.addEventListener('change', () => {
                if (reduceMotionMediaQueryList?.matches) {
                    this.stop();
                }
            });

            /**
             * Check the page visibility and stop/resume the video on 'visibilitychange' event.
             */
            document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));

            /**
             * Handle play/pause for background video.
             */
            this.videoControl?.addEventListener('click', () => {
                this.toggleVideo(this.paused);
                this.userForcedPause = this.paused;
            });

            /**
             * Listen for play/pause for on the video itself and set the {@link paused} state.
             */
            this.element?.addEventListener('click', () => {
                this.userForcedPause = !this.paused;
            });

            /**
             * Need to register a separate handler for background videos because
             * {@link Plyr} stops events on mobile devices.
             * @see https://github.com/sampotts/plyr/issues/968
             * @see https://github.com/sampotts/plyr/issues/946#issuecomment-1104978722
             */
            if (this.videoControl && window.matchMedia('(pointer: coarse)').matches) {
                /**
                 * Handle play/pause for background images.
                 */
                this.element?.addEventListener('click', () => {
                    this.toggleVideo(this.paused);
                    this.userForcedPause = this.paused;
                });
            }

            /**
             * Check if the Network Information API is supported.
             * Otherwise, enable the video regardless.
             */
            if (this.connection) {
                this.insufficientConnection = !this.checkConnectivity();
            }

            /**
             * Adjust background video controls.
             */
            this.plyr.on('play', () => {
                this.didPlay = true;
                this.videoControl?.classList.remove('is-paused');
            });

            /**
             * Adjust background video controls.
             */
            this.plyr.on('pause', () => {
                this.videoControl?.classList.add('is-paused');
            });

            /**
             * Check if the {@link this.options.autoplayMinimumConnection} option was set and if the user has not opted in for reduced motion.
             */
            if (this.options.autoplayMinimumConnection && !prefersReducedMotion) {
                this.element.preload = 'auto';
                this.element.autoplay = true;
                this.options.autoplay = true;

                /**
                 * Check if the current connection is insufficient and pause the video,
                 * Otherwise check if an autoplay was preferred and play it.
                 */
                this.toggleVideo(this.connection && this.insufficientConnection);
            }
        } else {
            logger.warn('WARNING: You are using "Video" without its necessary dependency "Plyr".');
        }
    }

    /**
     * Compare the current and the preferred downlink.
     * @return {boolean} - If the current connection is better than the preferred one.
     */
    checkConnectivity() {
        /**
         * If no preferred connection type was passed we assume a great connection type (4G).
         * @type {string}
         */
        const preferredConnectionType = this.options.autoplayMinimumConnection ?? '4g';

        const currentDownlink = EffectiveConnectionTypeEnum[this.connection?.effectiveType];
        const minDownlink = EffectiveConnectionTypeEnum[preferredConnectionType];
        return currentDownlink >= minDownlink;
    }

    /**
     * Handle play-state of video depending on if the current page is visible.
     * If the user paused the video via {@link this.userForcedPause} or
     * the video has not been played yet, do not play it when the
     * page visibility changes to unhidden/visible.
     */
    handleVisibilityChange() {
        if (!(this.userForcedPause || this.insufficientConnection || !this.didPlay)) {
            this.toggleVideo(!document.hidden);
        }
    }

    /**
     * Toggle video playback state.
     * @param {boolean} paused
     */
    toggleVideo(paused) {
        if (paused) {
            this.play();
            this.videoControl?.classList.remove('is-paused');
        } else {
            this.pause();
            this.videoControl?.classList.add('is-paused');
        }
    }

    /**
     * Play the video.
     */
    play() {
        this.plyr?.play();
    }

    /**
     * Pause the video.
     */
    pause() {
        this.plyr?.pause();
    }

    /**
     * Get the current play-state.
     * @returns {boolean}
     */
    get paused() {
        return this.plyr?.paused;
    }

}

VideoPlayer.register();

libs.Plyr = Plyr;
