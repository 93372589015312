/* ------------------------------------*\
    #EXPANDABLE CONTENT SCRIPT
\*------------------------------------ */

import PanelGroup from '../../../../../src/js/components/panelgroup';

export default class ExpandableContent extends PanelGroup {

    static componentName = 'expandable-content';

    static componentSelector = '.c-expandable-content';

    static defaultOptions = PanelGroup.extendDefaultOptions({
        btnSelector: '.c-expandable-content__trigger',
        panelSelector: '.c-expandable-content__collapse',
    });

    constructor() {
        super(...arguments);

        this.$$element.on('bronson_panelchanged', () => {
            this.setGroupClass();
        });

        this.setGroupClass();
    }

    setGroupClass() {
        this.element.classList.toggle('is-closed-within', this.selectedIndex === -1);
    }

}

ExpandableContent.register();
